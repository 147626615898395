import { useEffect, useState } from "react";
import { useUser } from "contexts/UserContext";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod";
import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import { InputText } from "components/formInputs/Inputs";
import { getClient, updateClient } from "backend/api/clients";
import AlertMessage from "components/general/AlertMessage";
import { Message } from "helpers/helpers";
import { MainPageCard, FormDiv, Text } from "helpers/generalStyles";
import GoogleMaps from "components/formInputs/addressAutocomplete/AddressAutocomplete";
import { useQuery } from "react-query";

export const ProfileInformations = (props: { t: any }) => {
  const { t } = props;
  const { user, setUser } = useUser();
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  const userSchema = zod.object({
    companyName: zod.string(),
    companyAddress: zod.string(),
    siren: zod.string().nullable().optional(),
    vat: zod.string().nullable().optional(),
    vatRate: zod.number().nullable().optional(),
    iban: zod.string().nullable().optional(),
    bic: zod.string().nullable().optional(),
    bankName: zod.string().nullable().optional(),
  });

  type UserFormData = zod.infer<typeof userSchema>;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: user
      ? {
          companyName: user.companyName,
          companyAddress: user.companyAddress,
          siren: user.siren,
          vat: user.vat,
          vatRate: user.vatRate,
          iban: user.iban,
          bic: user.bic,
          bankName: user.bankName,
        }
      : {},
  });

  const { refetch: refetchClientSetting } = useQuery({
    queryKey: ["client-setting"],
    queryFn: () => getClient(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setValue("companyName", data.companyName);
      setValue("companyAddress", data.companyAddress);
      setValue("siren", data.siren);
      setValue("vat", data.vat);
      setValue("iban", data.iban);
      setValue("bic", data.bic);
      setValue("bankName", data.bankName);
    },
  });

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  const handleSave = async (formData: UserFormData) => {
    try {
      const clientId = localStorage.getItem("clientId");
      if (!clientId) {
        setAlertMessage({
          message: t("error.clientIdNotFound"),
          type: "error",
        });
        return;
      }

      await updateClient(clientId, formData);
      refetchClientSetting();

      setAlertMessage({ message: t("success.update"), type: "success" });
    } catch (error) {
      console.error("Error updating client:", error);
      setAlertMessage({ message: t("error.updateFailed"), type: "error" });
    }
  };

  return (
    <MainPageCard>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />
      <FormDiv
        style={{ alignItems: "flex-start" }}
        onSubmit={handleSubmit(handleSave)}
      >
        <Text size="22px" weight={400}>
          {t("clientId")} {user?.id}
        </Text>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <h2 style={{ marginTop: 0 }}>{t("customerInformations")}</h2>
            <InputText
              label={t("inputLabels.companyName")}
              name="companyName"
              id="companyName"
              control={control}
              error={errors.companyName?.message}
              t={t}
            />
            <InputText
              label={t("inputLabels.siren")}
              name="siren"
              id="siren"
              control={control}
              error={errors.siren?.message}
              t={t}
            />
            <InputText
              label={t("inputLabels.vat")}
              name="vat"
              id="vat"
              control={control}
              error={errors.vat?.message}
              t={t}
            />
            <InputText
              label={t("inputLabels.vatRate")}
              name="vatRate"
              id="vatRate"
              inputType="number"
              control={control}
              t={t}
              sx={{ mb: 4 }}
            />
            <GoogleMaps
              label={t("inputLabels.companyAddress")}
              name="companyAddress"
              control={control}
              disabled={false}
              defaultValue={user?.companyAddress}
              t={t}
            />
          </Grid>
          {/* PAYMENT INFORMATIONS NOT ENABLED 
          
          <Grid item xs={12} md={6}>
            <h2>{t("Informations de Paiement")}</h2>
            <InputText
              label={t("inputLabels.iban")}
              name="iban"
              id="iban"
              control={control}
              error={errors.iban}
              t={t}
            />
            <InputText
              label={t("inputLabels.bic")}
              name="bic"
              id="bic"
              control={control}
              error={errors.bic}
              t={t}
            />
            <InputText
              label={t("inputLabels.bankName")}
              name="bankName"
              id="bankName"
              control={control}
              error={errors.bankName}
              t={t}
            />
          </Grid>*/}
        </Grid>
        <ButtonStyled
          sx={{ margin: "20px 0 0 0" }}
          type="submit"
          variant="contained"
          color="primary"
        >
          {t("save")}
        </ButtonStyled>
      </FormDiv>
    </MainPageCard>
  );
};

export default ProfileInformations;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
`;
