import { Cancel, Edit } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
    Avatar,
    Button,
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from "@mui/material";
import { InputMultiSelect, IOSSwitchLabel } from "components/formInputs/Inputs";
import DefaultProfile from "images/default-profile.png";
import { MuiTelInput } from "mui-tel-input";
import React from "react";

interface CardUserProps {
    avatar: string;
    setAvatar: (value: string) => void;
    setFileAvatar: (value: any) => void
    mimeType: string;
    setMimeType: (value: string) => void;
    base64: string;
    setBase64: (value: string) => void
    fullName: string;
    setFullName: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    phone: string;
    setPhone: (value: string) => void;
    role: string;
    setRole: (value: string) => void;
    emailAlert: boolean;
    setEmailAlert: (value: boolean) => void;
    smsAlert: boolean;
    setSmsAlert: (value: boolean) => void;
    selectedActivityIds: (string | number)[];
    setSelectedActivityIds: (value: string[] | number[]) => void;
    fileInputRef: React.RefObject<HTMLInputElement>;


    dataActivities: { id: string; name: string }[];
    handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
    handleOpenModalDelete: (value: any) => void;
    handleLockerOpen: (value: any) => void;
    onSave: () => void;
    item: any

    id: string;
    t?: (key: string) => string;
}

const CardUser: React.FC<CardUserProps> = ({
    avatar, setAvatar, setFileAvatar,
    fullName, setFullName,
    mimeType, setMimeType,
    base64, setBase64,
    email, setEmail,
    phone, setPhone,
    role, setRole,
    emailAlert, setEmailAlert,
    smsAlert, setSmsAlert,
    selectedActivityIds, setSelectedActivityIds,
    fileInputRef,
    dataActivities,
    handleSelectChange,
    handleOpenModalDelete,
    handleLockerOpen,
    onSave,
    item,
    id,
    t = (key) => key
}) => {

    const isFillAvatar = (v: string) => v || avatar;
    const showImage = (isLockedOpen: boolean, image: string, newImage: string) => !isLockedOpen ? image || DefaultProfile : newImage ? newImage : image || DefaultProfile;

    return (
        <Card
            sx={{
                width: "30.5%",
                minWidth: "30.5%",
                height: "100%",
                padding: 3,
                borderRadius: 5,
                backgroundColor: "#F9F9F9",
                border: "none",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
        >
            <Stack direction="column" alignItems="center" spacing={2}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                        justifyContent: "center",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    {!item.isLockerOpen && (
                        <>
                            <IconButton
                                aria-label="close"
                                color="error"
                                sx={{
                                    position: "absolute",
                                    top: -20,
                                    left: -20,
                                }}
                                onClick={() => handleOpenModalDelete(item)}
                            >
                                <Cancel />
                            </IconButton>
                        </>
                    )}

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        position={"relative"}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <Avatar src={showImage(item.isLockerOpen, item.profilePictureUrl, avatar)} sx={{ width: 80, height: 80 }} />
                        {item.isLockerOpen && (
                            <>
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    sx={{ position: "absolute", right: -10, bottom: -10 }}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <Edit />
                                </IconButton>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            const file = e.target.files[0];
                                            setFileAvatar(file)
                                            setAvatar(URL.createObjectURL(file));

                                            const mimeType = file.type;
                                            setMimeType(mimeType);

                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                const base64 = reader.result as string;
                                                setBase64(base64);
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                    style={{ display: "none" }}
                                />
                            </>)}
                    </Stack>

                    <IconButton
                        aria-label="close"
                        color="primary"
                        onClick={() => handleLockerOpen(item)}
                        sx={{
                            position: "absolute",
                            top: -20,
                            right: -20,
                        }}
                    >
                        {item.isLockerOpen ? (
                            <LockOpenIcon
                                fontSize={"small"}
                                color={"secondary"}
                                style={{ cursor: "pointer" }}
                            />
                        ) : (
                            <LockIcon
                                fontSize={"small"}
                                color={"primary"}
                                style={{ cursor: "pointer" }}
                            />
                        )}
                    </IconButton>
                </Stack>

                <Grid
                    container
                    sx={{
                        gap: 2,
                    }}
                >
                    <Grid item xs={12}>
                        {item.isLockerOpen ? (
                            <TextField
                                type="text"
                                placeholder={t("inputCard.fullname")}
                                label={t("inputCard.fullname")}
                                style={{
                                    outline: "none",
                                    width: "95%",
                                }}
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        ) : (
                            <TextField
                                type="text"
                                placeholder={t("inputCard.fullname")}
                                label={t("inputCard.fullname")}
                                style={{
                                    outline: "none",
                                    width: "95%",
                                }}
                                value={item.fullName}
                                disabled
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid
                    container
                    sx={{
                        gap: 2,
                    }}
                >
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            placeholder={t("inputCard.email")}
                            label={t("inputCard.email")}
                            style={{
                                outline: "none",
                                width: "95%",
                            }}
                            value={item.isLockerOpen ? email : item.email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={!item.isLockerOpen}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    sx={{
                        gap: 2,
                    }}
                >
                    <MuiTelInput
                        fullWidth
                        value={item.isLockerOpen ? phone : item.phoneNumber}
                        onChange={setPhone}
                        variant="outlined"
                        inputProps={{
                            pattern:
                                "(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})\\s*(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+)\\s*)?$",
                        }}
                        sx={{
                            mt: 0,
                            width: "100%",
                            "& input": {
                                padding: "14px 0",
                                border: "none",
                            },
                            border: "none",
                            outline: "none",
                        }}
                        disabled={!item.isLockerOpen}
                    />
                </Grid>

                <Grid
                    container
                    sx={{
                        gap: 2,
                    }}
                    style={{ height: "170px" }}
                >
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>{
                                t("inputCard.role")
                            }</InputLabel>
                            <Select
                                value={item.isLockerOpen ? role : item.role}
                                label={t("inputCard.role")}
                                onChange={(e) => setRole(e.target.value)}
                                disabled={!item.isLockerOpen}
                            >
                                <MenuItem value="Administrateur">
                                    Administrateur
                                </MenuItem>
                                <MenuItem value="Membre">Membre</MenuItem>
                                <MenuItem value="Invité">Invité</MenuItem>
                            </Select>
                        </FormControl>

                        {!item.isLockerOpen ? (
                            <>
                                {item.role === "Membre" && (
                                    <>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            {t('inputCard.editor_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.editor_member_value')}
                                            </span>
                                        </p>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                            }}
                                        >
                                            {t('inputCard.activities_no_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.activities_member_value')}
                                            </span>
                                        </p>
                                    </>
                                )}
                                {item.role === "Invité" && (
                                    <>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            {t('inputCard.editor_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.editor_invite_value')}
                                            </span>
                                        </p>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                            }}
                                        >
                                            {t('inputCard.no_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.no_access_invite_value')}
                                            </span>
                                        </p>
                                    </>
                                )}
                                {item.role === "Administrateur" && (
                                    <p
                                        style={{
                                            marginLeft: "10px",
                                            color: "#004643",
                                            fontWeight: "700",
                                            marginTop: "0",
                                        }}
                                    >
                                        {t('inputCard.editor_access')} :{" "}
                                        <span style={{ fontWeight: "300" }}>
                                            {t('inputCard.all_modules')}
                                        </span>
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                {role === "Membre" && (
                                    <>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            {t('inputCard.editor_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.editor_member_value')}
                                            </span>
                                        </p>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                            }}
                                        >
                                            {t('inputCard.activities_no_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.activities_member_value')}
                                            </span>
                                        </p>
                                    </>
                                )}
                                {role === "Invité" && (
                                    <>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                                marginBottom: "3px",
                                            }}
                                        >
                                            {t('inputCard.editor_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.editor_invite_value')}
                                            </span>
                                        </p>
                                        <p
                                            style={{
                                                marginLeft: "10px",
                                                color: "#004643",
                                                fontWeight: "700",
                                                marginTop: "0",
                                            }}
                                        >
                                            {t('inputCard.no_access')} :{" "}
                                            <span style={{ fontWeight: "400" }}>
                                                {t('inputCard.no_access_invite_value')}
                                            </span>
                                        </p>
                                    </>
                                )}
                                {role === "Administrateur" && (
                                    <p
                                        style={{
                                            marginLeft: "10px",
                                            color: "#004643",
                                            fontWeight: "700",
                                            marginTop: "0",
                                        }}
                                    >
                                        {t('inputCard.editor_access')} :{" "}
                                        <span style={{ fontWeight: "300" }}>
                                            {t('inputCard.all_modules')}
                                        </span>
                                    </p>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid
                    container
                    sx={{
                        gap: 2,
                    }}
                >
                    <Grid item xs={12}>
                        <InputMultiSelect
                            value={
                                !item.isLockerOpen
                                    ? item.activities
                                    : selectedActivityIds.map((id) => id.toString())
                            }
                            onChange={
                                !item.isLockerOpen ? () => { } : handleSelectChange
                            }
                            label={t("inputCard.activities")}
                            disabled={!item.isLockerOpen}
                            id={id || "activity_id"}
                            placeholder={t("inputCard.activities")}
                            name={"activity_id"}
                            options={dataActivities.map(
                                (activity: {
                                    id: { toString: () => any };
                                    name: any;
                                }) => ({
                                    value: activity.id.toString(),
                                    label: activity.name,
                                })
                            )}
                            required
                            error={null}
                            t={t}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    sx={{
                        gap: 2,
                    }}
                    paddingLeft={1.5}
                >
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                            }}
                        >
                            <IOSSwitchLabel
                                checked={
                                    item.isLockerOpen ? emailAlert : item.emailAlert
                                }
                                label={t("inputCard.toggle_email")}
                                disabled={!item.isLockerOpen}
                                value={
                                    item.isLockerOpen ? emailAlert : item.emailAlert
                                }
                                setValue={(v) => setEmailAlert(v)}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                            }}
                        >
                            <IOSSwitchLabel
                                checked={item.isLockerOpen ? smsAlert : item.smsAlert}
                                label={t("inputCard.toogle_sms")}
                                disabled={!item.isLockerOpen}
                                value={item.isLockerOpen ? smsAlert : item.smsAlert}
                                setValue={(v) => setSmsAlert(v)}
                            />
                        </div>
                    </Grid>
                </Grid>
                {/* <div style={{ position: "relative", left: "-9%" }}>
      </div> */}

                {/* Save button */}
                <Button
                    variant="contained"
                    color="info"
                    onClick={() => onSave()}
                    disabled={
                        !item.isLockerOpen ||
                        !fullName ||
                        !email ||
                        !phone ||
                        !role ||
                        selectedActivityIds.length === 0 ||
                        !isFillAvatar(item.profilePictureUrl)
                    }
                >
                    {t("inputCard.update_btn")}
                </Button>
            </Stack>
        </Card>
    )
};

export default CardUser;
