import { WidgetType } from "components/tabs/widget/WidgetTab.types";
import { WIDGETS_UPLOAD_SUCCESS } from "constants/widgetConstants";

export function widgetsReducer(state = {}, action: any) {
  const { type, payload } = action;
  switch (type) {
    case WIDGETS_UPLOAD_SUCCESS:
      return payload as WidgetType[];

    default:
      return state;
  }
}
