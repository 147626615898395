import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import useToggleStore from "store/toggle";

export default function LanguageSelectorWithFlag() {
  const { t, i18n } = useTranslation("auth");

  const language = useToggleStore((state) => state.language);
  const setLanguage = useToggleStore((state) => state.toggleLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <Select
      value={language}
      onChange={(e) => setLanguage(e.target.value as "en" | "fr")}
      label="language"
      sx={{
        width: "100%",
        color: "white",
        border: "none",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiSelect-icon": {
          color: "white",
        },
        boxShadow: "none",
      }}
    >
      <MenuItem value={"fr"} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/images/fr_flag.png"
          style={{ width: "25px", marginRight: "3px" }}
        />{" "}
        Français (FR)
      </MenuItem>
      <MenuItem value={"en"} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/images/eng_flag.png"
          style={{ width: "25px", marginRight: "3px" }}
        />{" "}
        English (EN)
      </MenuItem>
    </Select>
  );
}
