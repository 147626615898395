// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-channel {
  display: grid;
  gap: 3px;
  justify-items: center;
  width: 6rem;
  padding: 15px 30px;
  border: var(--primary-color) 2px solid;
  border-radius: 8px;
  background-color: var(--background-color-1);
  cursor: pointer;
}

.website-channel:hover,
.website-channel:focus,
.website-channel.active {
  color: white;
  background-color: var(--primary-color);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.website-channel:hover .website-channel-icon,
.website-channel.active .website-channel-icon {
  color: white;
}
.website-channel-icon {
  color: var(--primary-color);
}
.website-channel-name {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
}
.website-channel-label {
  text-transform: capitalize;
  font-size: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/channelCard/ChannelCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,sCAAsC;EACtC,kBAAkB;EAClB,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;;;EAGE,YAAY;EACZ,sCAAsC;EACtC,8CAA8C;AAChD;;AAEA;;EAEE,YAAY;AACd;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":[".website-channel {\n  display: grid;\n  gap: 3px;\n  justify-items: center;\n  width: 6rem;\n  padding: 15px 30px;\n  border: var(--primary-color) 2px solid;\n  border-radius: 8px;\n  background-color: var(--background-color-1);\n  cursor: pointer;\n}\n\n.website-channel:hover,\n.website-channel:focus,\n.website-channel.active {\n  color: white;\n  background-color: var(--primary-color);\n  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);\n}\n\n.website-channel:hover .website-channel-icon,\n.website-channel.active .website-channel-icon {\n  color: white;\n}\n.website-channel-icon {\n  color: var(--primary-color);\n}\n.website-channel-name {\n  text-transform: uppercase;\n  font-weight: 700;\n  font-size: 1.2rem;\n}\n.website-channel-label {\n  text-transform: capitalize;\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
