import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/logoPdf.svg";
import moment from "moment";
import { useQuery } from "react-query";
import {
  getSingleAverageBasket,
  getSingleCustomerLoyalty,
  getSingleTarifCustomer,
  PostSingleTarifCustomerOutput,
  PostSingleAverageBasketOutput,
  PostSingleCustomerLoyaltyOutput,
} from "backend/api/dashboard";
import TableReportTarif from "components/report/TableReportTarif";
import ReportSingleTarifChart from "components/report/ReportSingleTarifChart";
import RoyaltyCustomerPercentage from "components/dashboard/RoyaltyCustomerPercentage";
import StatisticPercentage from "components/dashboard/StatisticPercentage";
import { formaterEuro } from "helpers/helpers";
import Assistent from "components/navigation/Assistent";

const ReportSingle3 = (props: {
  title: string;
  actId: string;
  setStatusTarif: (value: boolean) => void;
  setStatusAvgBasket: (value: boolean) => void;
  setStatusCustomerRoyalty: (value: boolean) => void;
}) => {
  const {
    title,
    actId,
    setStatusTarif,
    setStatusCustomerRoyalty,
    setStatusAvgBasket,
  } = props;

  const { t } = useTranslation("dashboard");
  const [searchParams] = useSearchParams();
  const startDate: string | null = searchParams.get("startDate");
  const endDate: string | null = searchParams.get("endDate");

  const [tarifData, setTarifData] = useState<PostSingleTarifCustomerOutput>([]);
  const [singleAverageBasket, setSingleAverageBasket] =
    useState<PostSingleAverageBasketOutput>({ averageBasket: "" });
  const [singleCustomerLoyalty, setSingleCustomerLoyalty] =
    useState<PostSingleCustomerLoyaltyOutput>({
      customers: 0,
      shareOfCustomers: 0,
    });

  useQuery({
    queryKey: ["reportSingleTarif", startDate, endDate, actId],
    queryFn: () =>
      getSingleTarifCustomer({
        data: {
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD HH:mm")
            : "",
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : "",
          // "2024-03-02T20:00:00Z",
        },
        tarifId: Number(actId),
      }),
    enabled: !!startDate && !!endDate && !!actId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setTarifData(data);
      setStatusTarif(true);
    },
  });

  const getAverageBasketResponse = useQuery({
    queryKey: ["reportSingleAverageBasket", startDate, endDate, actId],
    queryFn: () =>
      getSingleAverageBasket({
        data: {
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD HH:mm")
            : "",
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : "",
          // "2024-03-02T20:00:00Z",
        },
        averageId: Number(actId),
      }),
    enabled: !!startDate && !!endDate && !!actId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleAverageBasket(data);
      setStatusAvgBasket(true);
    },
  });

  const getCustomerLoyaltyResponse = useQuery({
    queryKey: ["reportSingleCustomerLoyalty", startDate, endDate, actId],
    queryFn: () =>
      getSingleCustomerLoyalty({
        data: {
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD HH:mm")
            : "",
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : "",
          // "2024-03-02T20:00:00Z",
        },
        customerId: Number(actId),
      }),
    enabled: !!startDate && !!endDate && !!actId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSingleCustomerLoyalty(data);
      setStatusCustomerRoyalty(true);
    },
  });
  return (
    <div style={{ width: "210mm", minHeight: "297mm" }}>
      <Navbar title={t("titlePricesCustomer")} />
      <Assistent />
      <div style={{ padding: "0px 30px", marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <ActivityColumn>
            <p style={{ fontSize: 14 }}>
              {t("activity")} :{" "}
              <span style={{ color: "#2CA58D" }}>
                {title} du {moment(startDate).format("YYYY/MM/DD")} au{" "}
                {moment(endDate).format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </ActivityColumn>
        </div>
        <ActivityContent marginBottom="0px">
          <TableReportTarif t={t} listDataTarif={tarifData} />
          <ReportSingleTarifChart t={t} listDataTarif={tarifData} />
        </ActivityContent>
        <ActivityContent>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("titlePricesCustomer")} :{" "}
              <span style={{ fontWeight: 400 }}>{t("descTarif")}</span>
            </p>
          </CardWrapper>
        </ActivityContent>
        <ActivityContent marginBottom="0px">
          <StatisticPercentage
            title={t("averageBasket")}
            info={null}
            value={
              getAverageBasketResponse.status === "success"
                ? singleAverageBasket.averageBasket
                  ? formaterEuro(+singleAverageBasket.averageBasket)
                  : "-"
                : "-"
            }
            fontSize={14}
          />
          <RoyaltyCustomerPercentage
            t={t}
            valueCustomers={
              getCustomerLoyaltyResponse.status === "success"
                ? singleCustomerLoyalty.customers
                : "-"
            }
            valueRoyalty={
              getCustomerLoyaltyResponse.status === "success"
                ? parseFloat(
                  singleCustomerLoyalty.shareOfCustomers.toString()
                ).toFixed(0) + "%"
                : "-"
            }
          />
        </ActivityContent>
        <ActivityContent>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("averageBasket")} :{" "}
              <span style={{ fontWeight: 400 }}>{t("descAverageBasket")}</span>
            </p>
          </CardWrapper>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("royaltyCustomer")} :{" "}
              <span style={{ fontWeight: 400 }}>
                {t("descCustomerRoyalty")}
              </span>
            </p>
          </CardWrapper>
        </ActivityContent>
      </div>
    </div>
  );
};

const Navbar = (props: { title: string }) => {
  const { title } = props;
  return (
    <div>
      <div
        style={{
          backgroundColor: "#004346",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <img alt="logo" src={logo} style={{ width: 100 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: 400,
            fontSize: 35,
            height: 85,
            width: "100%",
            marginRight: 30,
          }}
        >
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

const ActivityContent = styled.div<{ marginBottom?: string }>`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "30px"};
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const CardWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  border-radius: 30px;
  padding: 0px 15px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;
export default ReportSingle3;
