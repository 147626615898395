import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, TextField } from "@mui/material";
import HopleisureSide from "../components/auth/HopleisureSide";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "react-query";
import {
  HopleisureTextButton,
  HopleisureLink,
  AuthTitle,
} from "../helpers/generalStyles";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  login as apiLogin,
  sendResetPasswordLink as apiSendResetPasswordLink,
} from "../backend/api/auth";
import LanguageSelector from "../components/general/LanguageSelector";
import AlertMessage from "../components/general/AlertMessage";
import { Message } from "../helpers/helpers";
import { useUser } from "contexts/UserContext";
import useStoreUser from "store/user";
import { getUserMe } from "backend/api/profile";

export default function Login() {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [formToDisplay, setFormToDisplay] = useState("login");
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  return (
    <Page>
      <Container>
        <HopleisureSide />
        <FormCard>
          <AlertMessage
            alertMessage={alertMessage.message}
            setAlertMessage={setAlertMessage}
            type={alertMessage.type}
            t={t}
          />
          <LanguageSelector />
          {formToDisplay === "login" ? (
            // LoginForm(
            //   showPassword,
            //   setShowPassword,
            //   setFormToDisplay,
            //   setAlertMessage,
            //   navigate,
            //   t,
            //   setUser
            // )

            <LoginForm
              navigate={navigate}
              showPassword={showPassword}
              setAlertMessage={setAlertMessage}
              setFormToDisplay={setFormToDisplay}
              setShowPassword={setShowPassword}
              setUser={setUser}
              t={t}
            />
          ) : (
            // ResetPasswordForm(setFormToDisplay, setAlertMessage, navigate, t)
            <ResetPasswordForm
              navigate={navigate}
              setAlertMessage={setAlertMessage}
              setFormToDisplay={setFormToDisplay}
              t={t}
            />
          )}
        </FormCard>
      </Container>
    </Page>
  );
}

type LoginFormProps = {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setFormToDisplay: (form: string) => void;
  setAlertMessage: any;
  navigate: (path: string) => void;
  t: (key: string) => string;
  setUser: (user: any) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  showPassword,
  setShowPassword,
  setFormToDisplay,
  setAlertMessage,
  navigate,
  t,
  setUser,
}) => {
  const loginFormInput = z.object({
    email: z.string().email(),
    password: z.string().min(8),
  });
  type LoginFormInput = z.infer<typeof loginFormInput>;

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginFormInput),
    defaultValues: {
      email: "charl.touret@gmail.com",
      password: "admin123",
    },
  });

  const { updateUser } = useStoreUser();

  const { refetch } = useQuery(["me"], getUserMe, {
    onError: (error: any) => {},
    enabled: false,
  });

  const mutateLogin = useMutation({
    mutationFn: (data: LoginFormInput) => apiLogin(data),
    onSuccess: (result) => {
      const accessToken = result?.data?.accessToken;
      localStorage.setItem("accessToken", accessToken);

      refetch()
        .then((res) => {
          const userId = res?.data?.id;
          const clientId = res?.data?.clientId;

          localStorage.setItem("userId", userId);
          localStorage.setItem("clientId", clientId);
          localStorage.setItem("role", res?.data?.role ?? "");

          updateUser({
            accessToken: accessToken,
            userId: userId,
            clientId: clientId,
            role: res?.data?.role ?? "",
            user: res?.data,
          });

          setUser(result.data.user);
          setTimeout(() => {
            navigate("/agenda");
            window.location.reload();
          }, 300);
        })
        .catch((error) => {
          localStorage.removeItem("accessToken");
          setAlertMessage({
            type: "error",
            message: t(error?.response?.data?.error),
          });
        });
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });
  const onSubmit: SubmitHandler<any> = (data: LoginFormInput) => {
    mutateLogin.mutate({ email: data.email, password: data.password });
  };

  return (
    <FormDiv onSubmit={handleSubmit(onSubmit)}>
      <AuthTitle>{t("loginTitle").toUpperCase()}</AuthTitle>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            id="email"
            label={t("email")}
            variant="standard"
            fullWidth={true}
            error={Boolean(errors.email)}
            helperText={errors.email ? t(errors?.email.type) : ""}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            id="password"
            label={t("password")}
            variant="standard"
            fullWidth={true}
            error={Boolean(errors.password)}
            helperText={errors.password ? t(errors?.password.type) : ""}
            {...field}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <HopleisureTextButton
        style={{ width: "100%", textAlign: "right" }}
        onClick={() => {
          clearErrors();
          setFormToDisplay("reset");
        }}
      >
        {t("forgotPwd")}
      </HopleisureTextButton>
      <Button variant="contained" type="submit" fullWidth={true}>
        {t("loginButton")}
      </Button>
      <HopleisureLink onClick={(e) => navigate("/register")}>
        {t("noAccount")}
      </HopleisureLink>
    </FormDiv>
  );
};

// Define the props type
type ResetPasswordFormProps = {
  setFormToDisplay: (form: string) => void;
  setAlertMessage: any;
  navigate: (path: string) => void;
  t: (key: string) => string;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  setFormToDisplay,
  setAlertMessage,
  navigate,
  t,
}) => {
  const resetPasswordFormInput = z.object({
    email: z.string().email(),
  });
  type ResetPasswordFormInput = z.infer<typeof resetPasswordFormInput>;

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetPasswordFormInput),
    defaultValues: {
      email: "",
    },
  });

  const mutateResetPassword = useMutation({
    mutationFn: async (data: ResetPasswordFormInput) =>
      apiSendResetPasswordLink(data),
    onSuccess: (result) => {
      setFormToDisplay("login");
      setAlertMessage({
        type: "success",
        message: t("resetPasswordEmailSent"),
      });
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });
  const onSubmit: SubmitHandler<any> = (data: ResetPasswordFormInput) => {
    mutateResetPassword.mutate({ email: data.email });
  };
  return (
    <FormDiv onSubmit={handleSubmit(onSubmit)}>
      <AuthTitle>{t("forgotPasswordTitle").toUpperCase()}</AuthTitle>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth={true}
            id="email"
            label={t("email")}
            variant="standard"
            error={Boolean(errors.email)}
            helperText={errors.email ? t(errors?.email.type) : ""}
            {...field}
          />
        )}
      />
      <HopleisureTextButton
        style={{ width: "100%", textAlign: "right" }}
        onClick={() => {
          clearErrors();
          setFormToDisplay("login");
        }}
      >
        {t("goBackToLogin")}
      </HopleisureTextButton>
      <Button fullWidth={true} variant="contained" type="submit">
        {t("resetPwdButton")}
      </Button>
      <HopleisureLink onClick={(e) => navigate("/register")}>
        {t("noAccountSubscribe")}
      </HopleisureLink>
    </FormDiv>
  );
};

const Page = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  background-color: #054643;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 30px;
  gap: 30px;
`;

const FormCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3;
  background-color: white;
  height: 100%;
  border-radius: 20px;
`;

const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 400px;
  gap: 30px;
`;
