import { zodResolver } from "@hookform/resolvers/zod";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Checkbox, InputText } from "../../formInputs/Inputs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  MainPageCard,
  FormDiv,
  SecondaryTitleText,
} from "../../../helpers/generalStyles";
import Locker from "../Locker";
import {
  activityHours,
  ActivityHours,
  Slot,
  upsertActivity as apiUpsertActivity,
} from "../../../backend/api/activities";
import { useMutation } from "react-query";
import { queryClient } from "../../..";
import AlertMessage from "../../general/AlertMessage";
import { Message } from "../../../helpers/helpers";
import styled from "styled-components";

const dayList: ("1" | "2" | "3" | "4" | "5" | "6" | "0")[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "0",
];

const daysNameMapping: any = {
  "1": "Lundi",
  "2": "Mardi",
  "3": "Mercredi",
  "4": "Jeudi",
  "5": "Vendredi",
  "6": "Samedi",
  "0": "Dimanche",
};

const daysNameMappingEn: any = {
  "1": "Monday",
  "2": "Tuesday",
  "3": "Wednesday",
  "4": "Thursday",
  "5": "Friday",
  "6": "Saturday",
  "0": "Sunday",
};

const initialHoursObject = {
  "1": { slots: [] },
  "2": { slots: [] },
  "3": { slots: [] },
  "4": { slots: [] },
  "5": { slots: [] },
  "6": { slots: [] },
  "0": { slots: [] },
};

export function HoursForm(props: {
  inputData: ActivityHours;
  activityId: number | null;
  isLockerOpen: boolean;
  setIsLockerOpen: any;
  navigate: any;
  t: any;
  isFrench: boolean;
}) {
  const {
    inputData,
    activityId,
    isLockerOpen,
    setIsLockerOpen,
    navigate,
    t,
    isFrench,
  } = { ...props };
  const [page, setPage] = useState("1");
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  useEffect(() => {
    setAlertMessage({ message: "", type: "success" });
  }, [inputData]);

  const mutateActivity = useMutation({
    mutationFn: (data: ActivityHours) =>
      apiUpsertActivity({
        hours: data,
        activityId: activityId ? String(activityId) : null,
      }),
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesWithDisabled"],
      });
      setIsLockerOpen(false);
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });

  const {
    control,
    handleSubmit,
    clearErrors,
    watch,
    formState,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(activityHours),
    values: {
      ...inputData,
      hours: inputData.hours ? inputData.hours : initialHoursObject,
      hasSpecialDates: Boolean(
        inputData?.dates && inputData.dates.from && inputData.dates.to
      ),
    },
  });

  const onSubmit: SubmitHandler<any> = (data: ActivityHours) => {
    mutateActivity.mutate(data);
  };

  const {
    fields: mondayFields,
    append: mondayAppend,
    remove: mondayRemove,
  } = useFieldArray({ control, name: "hours.1.slots" });
  const {
    fields: tuesdayFields,
    append: tuesdayAppend,
    remove: tuesdayRemove,
  } = useFieldArray({ control, name: "hours.2.slots" });
  const {
    fields: wednesdayFields,
    append: wednesdayAppend,
    remove: wednesdayRemove,
  } = useFieldArray({ control, name: "hours.3.slots" });
  const {
    fields: thursdayFields,
    append: thursdayAppend,
    remove: thursdayRemove,
  } = useFieldArray({ control, name: "hours.4.slots" });
  const {
    fields: fridayFields,
    append: fridayAppend,
    remove: fridayRemove,
  } = useFieldArray({ control, name: "hours.5.slots" });
  const {
    fields: saturdayFields,
    append: saturdayAppend,
    remove: saturdayRemove,
  } = useFieldArray({ control, name: "hours.6.slots" });
  const {
    fields: sundayFields,
    append: sundayAppend,
    remove: sundayRemove,
  } = useFieldArray({ control, name: "hours.0.slots" });

  const slotIterator = (dayNumber: string) => {
    if (dayNumber === "1") return mondayFields;
    if (dayNumber === "2") return tuesdayFields;
    if (dayNumber === "3") return wednesdayFields;
    if (dayNumber === "4") return thursdayFields;
    if (dayNumber === "5") return fridayFields;
    if (dayNumber === "6") return saturdayFields;
    if (dayNumber === "0") return sundayFields;
  };

  const appendSlot = (dayNumber: string, slot: Slot) => {
    if (dayNumber === "1") mondayAppend(slot);
    if (dayNumber === "2") tuesdayAppend(slot);
    if (dayNumber === "3") wednesdayAppend(slot);
    if (dayNumber === "4") thursdayAppend(slot);
    if (dayNumber === "5") fridayAppend(slot);
    if (dayNumber === "6") saturdayAppend(slot);
    if (dayNumber === "0") sundayAppend(slot);
  };

  const removeSlot = (dayNumber: string, index: number) => {
    if (dayNumber === "1") mondayRemove(index);
    if (dayNumber === "2") tuesdayRemove(index);
    if (dayNumber === "3") wednesdayRemove(index);
    if (dayNumber === "4") thursdayRemove(index);
    if (dayNumber === "5") fridayRemove(index);
    if (dayNumber === "6") saturdayRemove(index);
    if (dayNumber === "0") sundayRemove(index);
  };
  const values = getValues();
  // we need to empty dates when hasSpecialDates become false
  useEffect(() => {
    if (!values.hasSpecialDates) {
      setValue("dates", undefined);
    }
  }, [values.hasSpecialDates]);

  return (
    <MainPageCard>
      <Locker
        isOpen={isLockerOpen}
        onClick={() => setIsLockerOpen(!isLockerOpen)}
      />
      <SecondaryTitleText>
        {t("hoursSubTitle").toUpperCase()}
      </SecondaryTitleText>
      {/* cause FormDiv have minWidth: 400px and it's too much for here */}
      <FormDiv style={{ minWidth: "200px" }} onSubmit={handleSubmit(onSubmit)}>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <TemporaryActivityDiv>
          <Checkbox
            id={"hasSpecialDates"}
            name={"hasSpecialDates"}
            control={control}
            label={t("noPermanentActivity")}
            disabled={!isLockerOpen}
            t={t}
          />
          {watch().hasSpecialDates ? (
            <>
              <InputText
                id={`dates.from`}
                name={`dates.from`}
                inputType="date"
                required
                disabled={!isLockerOpen}
                control={control}
                error={errors.dates ? errors.dates.from?.message : null}
                t={t}
              />
              <InputText
                id={`dates.to`}
                name={`dates.to`}
                inputType="date"
                required
                disabled={!isLockerOpen}
                control={control}
                error={errors.dates ? errors.dates.to?.message : null}
                t={t}
              />
            </>
          ) : null}
        </TemporaryActivityDiv>

        <TabSize>
          <TabContext value={page}>
            <TabList
              onChange={(event, value) => setPage(value)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {dayList.map((day, index) => (
                <Tab
                  label={
                    isFrench ? daysNameMapping[day] : daysNameMappingEn[day]
                  }
                  key={index}
                  value={day}
                  style={{
                    borderBottom: 1,
                    backgroundColor: values.hours[day].slots.length
                      ? "white"
                      : "lightGrey",
                  }}
                />
              ))}
            </TabList>
            {dayList.map((day, index) => {
              const errorsOnDay = errors.hours
                ? errors.hours[day]?.slots
                : null;
              return (
                <TabPanel
                  key={index}
                  value={day}
                  style={{
                    margin: 0,
                    padding: 0,
                    width: "100%",
                    maxHeight: "250px",
                    overflowY: "scroll",
                    borderBottom: "solid 1px #f2f2f2",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "20px",
                    }}
                  >
                    {slotIterator(day)?.map((slot: Slot, index: number) => (
                      <div
                        key={`hours.${day}.slots[${index}]`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            borderBottom: "solid lightGrey 1px",
                          }}
                        >
                          <InputText
                            id={`hours.${day}.slots[${index}].from`}
                            name={`hours.${day}.slots[${index}].from`}
                            label={t("start")}
                            inputType="time"
                            required
                            disabled={!isLockerOpen}
                            control={control}
                            error={
                              errorsOnDay && errorsOnDay[index]
                                ? errorsOnDay[index]?.from?.message
                                : null
                            }
                            t={t}
                          />
                          <InputText
                            id={`hours.${day}.slots[${index}].to`}
                            name={`hours.${day}.slots[${index}].to`}
                            label={t("end")}
                            inputType="time"
                            required
                            disabled={!isLockerOpen}
                            control={control}
                            error={
                              errorsOnDay && errorsOnDay[index]
                                ? errorsOnDay[index]?.to?.message
                                : null
                            }
                            t={t}
                          />
                        </div>
                        <DeleteIcon
                          style={{
                            color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            isLockerOpen
                              ? removeSlot(day, index)
                              : console.log("locker closed")
                          }
                        />
                      </div>
                    ))}
                    <AddCircleOutlineIcon
                      style={{
                        color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        isLockerOpen
                          ? appendSlot(day, { from: "00:00", to: "00:00" })
                          : console.log("locker closed")
                      }
                    />
                  </div>
                </TabPanel>
              );
            })}
          </TabContext>
        </TabSize>

        <Button
          disabled={!isLockerOpen || mutateActivity.isLoading}
          color="info"
          variant="contained"
          type="submit"
        >
          {t("save")}
        </Button>
      </FormDiv>
    </MainPageCard>
  );
}

const TemporaryActivityDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const TabSize = styled.div`
  max-width: 550px;
  @media (max-width: 1500px) {
    max-width: 350px;
  }
  @media (max-width: 900px) {
    max-width: 250px;
  }
`;
