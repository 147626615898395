import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navigation/Navbar";
import MainTitle from "../components/general/MainTitle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ActivityType from "../components/activities/ActivityType";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MainPageCard } from "../helpers/generalStyles";
import { HoursForm } from "../components/activities/formCards/HoursForm";
import { ChannelsForm } from "../components/activities/formCards/ChannelsForm";
import { PricesForm } from "../components/activities/formCards/PricesForm";
import { InformationsForm } from "../components/activities/formCards/InformationsForm";
import { useQuery } from "react-query";
import { Activity, getActivities } from "../backend/api/activities";
import { emptyActivity } from "../helpers/constants";
import Assistent from "components/navigation/Assistent";

interface ActivityForm extends Omit<Activity, "id"> {
  id?: number;
}

export default function Activities() {
  const { t, i18n } = useTranslation("activities");
  const navigate = useNavigate();

  const [activities, setActivities] = useState<ActivityForm[]>([]);
  const [activity, setActivity] = useState<ActivityForm>();

  const activityId = (activity as Activity)?.id || null;

  const [isInformationsLockerOpen, setIsInformationsLockerOpen] =
    useState(false);
  const [isHoursLockerOpen, setIsHoursLockerOpen] = useState(false);
  const [isPricesLockerOpen, setIsPricesLockerOpen] = useState(false);
  const [isChannelsLockerOpen, setIsChannelsLockerOpen] = useState(false);

  const getActivitiesResponse = useQuery({
    queryKey: ["getActivitiesWithDisabled"],
    queryFn: () => getActivities(true),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setActivities(data);
      // will go back to the last activity when updated, if no activity with id selected
      if (data.length > 0 && !activity?.id) {
        setActivity(data[data.length - 1]);
      } else {
        const activity = data.find((item) => item.id === activityId);
        setActivity(activity);
      }
    },
  });

  useEffect(() => {
    if (!activity?.id) setIsInformationsLockerOpen(true);
    else setIsInformationsLockerOpen(false);
    setIsHoursLockerOpen(false);
    setIsPricesLockerOpen(false);
    setIsChannelsLockerOpen(false);
  }, [activities, activity]);

  return (
    <Page>
      <Navbar />
      <Assistent />
      <Container>
        <MainTitle title={t("myActivitiesTitle")} />
        {!getActivitiesResponse.isLoading ? (
          <>
            <ActivitiesHeaderCard
              activity={activity}
              setActivity={setActivity}
              setActivities={setActivities}
              activities={activities}
            />
            {activity ? (
              <ActivityContent>
                <ActivityColumn>
                  <InformationsForm
                    inputData={activity.informations}
                    activityId={activityId}
                    isLockerOpen={isInformationsLockerOpen}
                    setIsLockerOpen={setIsInformationsLockerOpen}
                    navigate={navigate}
                    t={t}
                  />
                </ActivityColumn>
                <ActivityColumn>
                  <HoursForm
                    inputData={activity.hours}
                    activityId={activityId}
                    isLockerOpen={isHoursLockerOpen}
                    setIsLockerOpen={setIsHoursLockerOpen}
                    navigate={navigate}
                    t={t}
                    isFrench={i18n.language === "fr"}
                  />
                  <PricesForm
                    inputData={{ prices: activity.prices }}
                    activityId={activityId}
                    isLockerOpen={isPricesLockerOpen}
                    setIsLockerOpen={setIsPricesLockerOpen}
                    navigate={navigate}
                    t={t}
                  />
                  <ChannelsForm
                    inputData={activity.channels}
                    activityId={activityId}
                    isLockerOpen={isChannelsLockerOpen}
                    setIsLockerOpen={setIsChannelsLockerOpen}
                    navigate={navigate}
                    t={t}
                  />
                </ActivityColumn>
              </ActivityContent>
            ) : null}
          </>
        ) : null}
      </Container>
    </Page>
  );
}

function ActivitiesHeaderCard(props: {
  activity: ActivityForm | undefined;
  setActivity: Dispatch<SetStateAction<ActivityForm | undefined>>;
  setActivities: Dispatch<SetStateAction<ActivityForm[]>>;
  activities: ActivityForm[];
}) {
  const { activity, setActivity, setActivities, activities } = props;
  return (
    <MainPageCard style={{ minHeight: "100px" }}>
      <AddCircleOutlineIcon
        fontSize="large"
        style={{
          color: "#4FD1C5",
          cursor: "pointer",
          position: "absolute",
          top: -15,
          right: -10,
        }}
        onClick={() => {
          const containAlreadyANewActivity = activities.find(
            (item) => !item.id
          );
          if (!containAlreadyANewActivity) {
            setActivities([...activities, emptyActivity]);
            setActivity(emptyActivity);
          }
        }}
      />
      {activities.map((activityElem, index) => (
        <ActivityType
          key={index}
          type={activityElem.informations.category}
          title={activityElem.informations.name}
          selected={
            activity !== undefined &&
            activity.informations.name === activityElem.informations.name
          }
          disabled={!activityElem.informations.isEnabled}
          onClick={() => {
            setActivity(activityElem);
          }}
        />
      ))}
    </MainPageCard>
  );
}

const Page = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  background-color: #e0e1e2;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
`;

const ActivityContent = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
