import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button } from "@mui/material";
import { getClient, updateClient } from "backend/api/clients";
import { updateClientSettings } from "backend/api/clientSettings";
import { InputPhone, InputText } from "components/formInputs/Inputs";
import AlertMessage from "components/general/AlertMessage";
import { useUser } from "contexts/UserContext";
import { FormDiv, MainPageCard } from "helpers/generalStyles";
import { Message, snakeToCamelString } from "helpers/helpers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import styled from "styled-components";
import * as zod from "zod";

export const ProfileSettings = (props: { t: any, id: string }) => {
  const { t, id } = props;
  const { user, setUser } = useUser();
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  const userSchema = zod.object({
    email: zod.string(),
    contactPhoneNumber: zod.string().min(10),
  });
  const [idClient, setIdClient] = useState<number>(0);

  type UserFormData = zod.infer<typeof userSchema>;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      email: "",
      contactPhoneNumber: "",
    }
    // defaultValues: user
    //   ? {
    //     email: user.email,
    //     contactPhoneNumber: user.contactPhoneNumber,
    //   }
    //   : {},
  });

  const phoneNumber = watch("contactPhoneNumber");

  useEffect(() => {
    setIsUserUpdated(phoneNumber !== user?.contactPhoneNumber);
  }, [phoneNumber, user?.contactPhoneNumber]);

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  const { refetch: refetchClientSetting } = useQuery({
    queryKey: ["client-setting"],
    queryFn: () => getClient(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setValue("email", data?.contactEmail);
      setValue("contactPhoneNumber", data?.contactPhoneNumber);
      setIdClient(data?.id);
    },
  });

  const handleSave = async (formData: UserFormData) => {
    // if (user) {
    await updateClient(idClient, formData);
    refetchClientSetting();
    // setUser({ ...user, ...formData });
    setAlertMessage({ message: t("success.update"), type: "success" });
    setIsUserUpdated(false); // Reset the update flag after saving
    // }
  };

  const handleChangeAlert = async (field: string, value: boolean) => {
    if (user) {
      await updateClientSettings(user!.id, { [field]: value });
      setUser({
        ...user,
        clientSettings: {
          ...(user.clientSettings ?? {}),
          [snakeToCamelString(field)]: value,
          clientId: user.clientSettings?.clientId ?? 0,
        },
      });
      setAlertMessage({ message: t("success.update"), type: "success" });
      setIsUserUpdated(false); // Reset the update flag after saving
    }
  };

  return (
    <MainPageCard id={id}>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />
      <FormDiv style={{ gap: 0 }} onSubmit={handleSubmit(handleSave)}>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0",
        }}>
          <h2 style={{ margin: 0 }}>{t("titleSetting").toUpperCase()}</h2>
        </Box>


        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "0",
          alignItems: "center",
        }}>
          <InputText
            label={t("inputLabels.email")}
            name="email"
            id="email"
            control={control}
            error={errors.email}
            disabled
            sx={{ mb: 2, mt: 5 }}
            t={t}
          />

          <InputPhone
            label={t("inputLabels.phone")}
            name="contactPhoneNumber"
            id="contactPhoneNumber"
            control={control}
            error={errors.contactPhoneNumber?.message}
            t={t}
          />
        </Box>

        {isUserUpdated && (
          <ButtonStyled
            sx={{ margin: "0 0 10px 20px", height: "60px" }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("save")}
          </ButtonStyled>
        )}
      </FormDiv>

      {/* <FormDiv style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid container item xs={12} md={6} spacing={2}>
            <MainTitleText align="start" style={{ marginBottom: "10px" }}>
              {t("alerts")}
            </MainTitleText>
            <Text size="18px" weight={500} style={{ marginBottom: "10px" }}>
              {t("receiveEmail")}
            </Text>

            <UncontrolledSwitch
              key={"booking-email-alert"}
              label={t("inputLabels.eachBooking")}
              isActive={user?.clientSettings?.emailBookingAlert}
              disabled={false}
              t={t}
              style={{ marginBottom: "10px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeAlert("email_booking_alert", e.target.checked)
              }
            />


            <UncontrolledSwitch
              key={"booking-sms-alert"}
              label={t("inputLabels.eachCancellation")}
              isActive={user?.clientSettings?.smsBookingAlert}
              disabled={false}
              t={t}
              style={{ marginBottom: "30px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeAlert("sms_booking_alert", e.target.checked)
              }
            />

            <Text size="18px" weight={500} style={{ marginBottom: "10px" }}>
              {t("receiveSms")}
            </Text>

            <UncontrolledSwitch
              key={`calcellation-email-alert`}
              label={t("inputLabels.eachBooking")}
              isActive={user?.clientSettings?.emailCancellationAlert}
              disabled={false}
              t={t}
              style={{ marginBottom: "10px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeAlert("email_cancellation_alert", e.target.checked)
              }
            />
            <UncontrolledSwitch
              key={"calcellation-sms-alert"}
              label={t("inputLabels.eachCancellation")}
              isActive={user?.clientSettings?.smsCancellationAlert}
              disabled={false}
              t={t}
              style={{ marginBottom: "10px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeAlert("sms_cancellation_alert", e.target.checked)
              }
            />

          </Grid>
        </Grid>
      </FormDiv> */}
    </MainPageCard>
  );
};

export default ProfileSettings;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
`;
