import { Dialog, DialogTitle, DialogContent, Modal, Box } from "@mui/material";
import { ExtraInfosType } from "components/agenda/types";
import { arePropsEqual, isArrayEmpty } from "helpers/helpers";
import React from "react";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

const BookingExtraInfosModal = (props: {
  openBookingExtraInfosModal: boolean;
  setOpenBookingExtraInfosModal: Function;
  extraInfos: ExtraInfosType;
  t: Function;
}) => {
  const {
    openBookingExtraInfosModal,
    setOpenBookingExtraInfosModal,
    extraInfos,
    t,
  } = props;

  return (
    <Dialog
      open={openBookingExtraInfosModal}
      onClose={() => setOpenBookingExtraInfosModal(false)}
      color="#f9f9f9"
      maxWidth={false}
    >
      <div
        style={{
          backgroundColor: "#f9f9f9",
          width: "52vw",
          paddingBottom: "1rem ",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DisabledByDefaultIcon
          onClick={() => setOpenBookingExtraInfosModal(false)}
          color="error"
          style={{ alignSelf: "end", cursor: "pointer" }}
        />
        <DialogTitle
          style={{
            color: "#2ca58d",
            textTransform: "uppercase",
            fontSize: "1.5rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {t("visitorInformations")}
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "grid", gap: "1rem" }}>
            {!isArrayEmpty(extraInfos) && (
              <>
                {extraInfos?.map((extraInfo, index) => (
                  <div key={index} style={{ display: "grid", gap: "10px" }}>
                    <span
                      style={{
                        color: "#004346",
                        fontWeight: "700",
                        textTransform: "capitalize",
                      }}
                    >
                      {extraInfo.question}
                    </span>
                    <span
                      style={{
                        border: "2px solid #878787",
                        color: "#545759",
                        padding: "10px 10px 2rem 10px",
                        borderRadius: "5px",
                      }}
                    >
                      {extraInfo.answer}
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default React.memo(BookingExtraInfosModal, arePropsEqual);
