// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-card {
  border-radius: 20px;
  color: var(--primary-color);
  border: var(--primary-color) 2px solid;
  background-color: var(--background-color-1);
  font-weight: 700;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.widget-card:hover {
  color: white;
  background-color: #248a75fa;
  transform: translateY(-3px);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.widget-card.active {
  color: white;
  background-color: var(--primary-color);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(0);
}
`, "",{"version":3,"sources":["webpack://./src/components/widgetCard/WidgetCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,sCAAsC;EACtC,2CAA2C;EAC3C,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,2BAA2B;EAC3B,8CAA8C;AAChD;;AAEA;EACE,YAAY;EACZ,sCAAsC;EACtC,8CAA8C;EAC9C,wBAAwB;AAC1B","sourcesContent":[".widget-card {\n  border-radius: 20px;\n  color: var(--primary-color);\n  border: var(--primary-color) 2px solid;\n  background-color: var(--background-color-1);\n  font-weight: 700;\n  padding: 10px 15px;\n  font-size: 14px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n}\n\n.widget-card:hover {\n  color: white;\n  background-color: #248a75fa;\n  transform: translateY(-3px);\n  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);\n}\n\n.widget-card.active {\n  color: white;\n  background-color: var(--primary-color);\n  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
