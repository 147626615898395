import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/logoPdf.svg";
import moment from "moment";
import { useQuery } from "react-query";
import {
  getSingleDataHeatmap,
  PostSingleDataHeatmapOutput,
} from "backend/api/dashboard";
import ReportFollowedAverageFilling from "components/report/ReportFollowedAverageFilling";
import Assistent from "components/navigation/Assistent";

const ReportSingle4 = (props: {
  title: string;
  actId: string;
  setStatusHeatmap: (value: boolean) => void;
}) => {
  const { title, actId, setStatusHeatmap } = props;
  const { t } = useTranslation("dashboard");
  const [searchParams] = useSearchParams();
  const startDate: string | null = searchParams.get("startDate");
  const endDate: string | null = searchParams.get("endDate");

  const [heatmapData, setHeatmapData] = useState<PostSingleDataHeatmapOutput>({
    data: [],
    availableSlots: {
      sundaySlots: 0,
      mondaySlots: 0,
      tuesdaySlots: 0,
      wednesdaySlots: 0,
      thursdaySlots: 0,
      fridaySlots: 0,
      saturdaySlots: 0,
    },
  });

  useQuery({
    queryKey: ["reportHeatmapData", startDate, endDate, actId],
    queryFn: () =>
      getSingleDataHeatmap({
        data: {
          startDate: startDate
            ? moment(startDate).format("YYYY-MM-DD HH:mm")
            : "",
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : "",
          // "2024-03-02T20:00:00Z",
        },
        activityId: Number(actId),
      }),
    enabled: !!startDate && !!endDate && !!actId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setHeatmapData(data);
      setStatusHeatmap(true);
    },
  });

  return (
    <div style={{ width: "210mm", minHeight: "297mm" }}>
      <Navbar title={t("monitoringFilling")} />
      <Assistent />
      <div style={{ padding: "0px 30px", marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <ActivityColumn>
            <p style={{ fontSize: 14 }}>
              {t("activity")} :{" "}
              <span style={{ color: "#2CA58D" }}>
                {title} du {moment(startDate).format("YYYY/MM/DD")} au{" "}
                {moment(endDate).format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </ActivityColumn>
        </div>
        <ActivityContent marginBottom="10px">
          <ReportFollowedAverageFilling t={t} heatmapData={heatmapData} />
        </ActivityContent>
        {/* <ActivityContent>
          <CardWrapper>
            <div>
              <p style={{ fontWeight: 800, fontSize: 12 }}>
                {t("mediumFilling")} :{" "}
                <span style={{ fontWeight: 400 }}>
                  {t("descMediumFilling")}
                </span>
              </p>
            </div>
            <div></div>
          </CardWrapper>
        </ActivityContent> */}
      </div>
    </div>
  );
};

const Navbar = (props: { title: string }) => {
  const { title } = props;
  return (
    <div>
      <div
        style={{
          backgroundColor: "#004346",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <img alt="logo" src={logo} style={{ width: 100 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: 400,
            fontSize: 35,
            height: 85,
            width: "100%",
            marginRight: 30,
          }}
        >
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

const ActivityContent = styled.div<{ marginBottom?: string }>`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "30px"};
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export default ReportSingle4;
