import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";
import "./ColorSelector.css";
import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { arePropsEqual } from "helpers/helpers";

const ColorSelector = (props: {
  initialColor: string;
  label: string;
  updateWidgetColor: Function;
}) => {
  const { initialColor, label, updateWidgetColor } = props;

  const [color, setColor] = useColor(initialColor);
  const [openColorPicker, setOpenColorPicker] = useState(false);

  const changeColor = (newColor: IColor) => {
    setColor(newColor);
    updateWidgetColor(newColor.hex);
  };

  return (
    <div className="color-selector-container">
      <div className="color-selector">
        <div
          className="color-sample-container"
          onClick={() => setOpenColorPicker(!openColorPicker)}
        >
          <span
            className="color-sample"
            style={{ backgroundColor: color.hex }}
          ></span>
          <span className="color-code">{color.hex}</span>
        </div>
        <span className="color-label">{label}</span>
      </div>
      {openColorPicker && (
        <ClickAwayListener onClickAway={() => setOpenColorPicker(false)}>
          <div>
            <ColorPicker
              color={color}
              hideInput={["rgb", "hsv"]}
              onChange={changeColor}
              height={140}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default React.memo(ColorSelector, arePropsEqual);
