import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.css";

function Loader() {
  return (
    <div className="loader ">
      <CircularProgress size={70} />
    </div>
  );
}

export default Loader;
