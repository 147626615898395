import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface AppState {
    isSidebarOpen: boolean
    toggleSidebar: () => void
    language: 'en' | 'fr'
    toggleLanguage: (v: 'en' | 'fr') => void
}

const useToggleStore = create(
    persist<AppState>(
        (set) => ({
            isSidebarOpen: true,
            toggleSidebar: () =>
                set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
            language: 'en',
            toggleLanguage: (v: 'en' | 'fr') =>
                set((state) => ({
                    language: state.language === 'en' ? 'fr' : 'en',
                })),
        }),
        {
            name: 'toggle',
        }
    )
)

export default useToggleStore
