import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import useToggleStore from "store/toggle";
import moment from "moment";
import "moment/locale/fr";

export default function LanguageSelectorWithFlag() {
  const { t, i18n } = useTranslation("auth");

  const language = useToggleStore((state: any) => state.language);
  const setLanguage = useToggleStore((state: any) => state.toggleLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
    moment.locale(language);
  }, [i18n, language]);

  return (
    <Select
      value={language}
      onChange={(e) => setLanguage(e.target.value as "en" | "fr")}
      label="language"
      style={{
        width: "100%",
        color: "white",
        border: "#054643",
        outline: "#054643",
      }}
    >
      <MenuItem value={"fr"} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/images/fr_flag.png"
          style={{ width: "25px", marginRight: "3px" }}
        />{" "}
        Français (FR)
      </MenuItem>
      <MenuItem value={"en"} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/images/eng_flag.png"
          style={{ width: "25px", marginRight: "3px" }}
        />{" "}
        English (EN)
      </MenuItem>
    </Select>
  );
}
