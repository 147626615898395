const general: any = {
  en: {
    introduceHopbooks: "Introduce HOPBOOKS",
    agenda: "Agenda",
    bookings: "Bookings",
    discounts: "Discounts",
    dashboard: "Dashboard",
    activities: "Activities",
    profile: "Profile",
    site_internet: "Website",
    emailing_sms: "Emailing / SMS",
    tools: "Tools",
    needAssistance: "Need Assistance ?",
    needAssistanceText: "Our team will contact you as soon as possible !",
    needAssistanceButton: "Contact Hopleisure",
    logout: "Logout",
    invoicing: "Invoicing",
  },
  fr: {
    introduceHopbooks: "présente HOPBOOKS",
    agenda: "Agenda",
    bookings: "Réservations",
    discounts: "Promotions",
    dashboard: "Tableau de bord",
    activities: "Activités",
    profile: "Mon profil",
    site_internet: "Site internet",
    emailing_sms: "Envoi d'e- mails / SMS",
    tools: "Equipement",
    needAssistance: "Besoin d'aide ?",
    needAssistanceText:
      "Notre équipe reviendra vers vous dans les meilleurs délais !",
    needAssistanceButton: "Contacter Hopleisure",
    logout: "Déconnexion",
    invoicing: "Facturation",
  },
};

export default general;
