import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DiscountIcon from "@mui/icons-material/Discount";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import TodayIcon from "@mui/icons-material/Today";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import { Avatar, Button, IconButton, Stack } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useUser } from "contexts/UserContext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HopleisureTextButton } from "../../helpers/generalStyles";
// import Logo from "../../images/logo.svg";
import Logo from "images/hopleisure.svg";
import DefaultProfile from "images/default-profile.png";
import ChangeUserIcon from "images/change-user.svg";
import ArrorSidebar from "images/arrow.svg";
import LanguageSelectorWithFlag from "components/general/LanguageSelectorWithFlag";
import useToggleStore from "store/toggle";
import useStoreUser from "store/user";
import { RoleAccess } from "helpers/role";

const menuInfos: any = {
  agenda: {
    title: "agenda",
    paths: ["/agenda"],
    disabled: false,
    whiteIcone: <TodayIcon style={{ color: "white" }} />,
    greenIcone: <TodayIcon style={{ color: "#4FD1C5" }} />,
  },
  bookings: {
    title: "bookings",
    paths: ["/bookings"],
    disabled: false,
    whiteIcone: <EventAvailableIcon style={{ color: "white" }} />,
    greenIcone: <EventAvailableIcon style={{ color: "#4FD1C5" }} />,
  },
  discounts: {
    title: "discounts",
    paths: ["/discounts"],
    disabled: false,
    whiteIcone: <DiscountIcon style={{ color: "white" }} />,
    greenIcone: <DiscountIcon style={{ color: "#4FD1C5" }} />,
  },
  dashboard: {
    title: "dashboard",
    paths: ["/dashboard"],
    disabled: false,
    whiteIcone: <SpaceDashboardIcon style={{ color: "white" }} />,
    greenIcone: <SpaceDashboardIcon style={{ color: "#4FD1C5" }} />,
    // isLocked: true,
    // isLockedWhiteIcone: <LockIcon style={{ color: "white", width: 20 }} />,
    // isLockedGreenIcone: <LockIcon style={{ color: "#4FD1C5" }} />,
  },
  activities: {
    title: "activities",
    paths: ["/activities"],
    disabled: false,
    whiteIcone: <RocketLaunchIcon style={{ color: "white" }} />,
    greenIcone: <RocketLaunchIcon style={{ color: "#4FD1C5" }} />,
  },
  profile: {
    title: "profile",
    paths: ["/profile", "/facturation"],
    disabled: false,
    whiteIcone: <AccountBoxIcon style={{ color: "white" }} />,
    greenIcone: <AccountBoxIcon style={{ color: "#4FD1C5" }} />,
  },
  site_internet: {
    title: "site_internet",
    paths: ["/site-internet"],
    disabled: true,
    whiteIcone: <LanguageIcon style={{ color: "white" }} />,
    greenIcone: <LanguageIcon style={{ color: "#4FD1C5" }} />,
  },
  emailing: {
    title: "emailing_sms",
    paths: ["/emailing"],
    disabled: false,
    whiteIcone: <EmailIcon style={{ color: "white" }} />,
    greenIcone: <EmailIcon style={{ color: "#4FD1C5" }} />,
  },
  // website: {
  //   title: "website",
  //   paths: ["/website"],
  //   disabled: false,
  //   whiteIcone: <LanguageIcon style={{ color: "white" }} />,
  //   greenIcone: <LanguageIcon style={{ color: "#4FD1C5" }} />,
  // },
  // tools: {
  //   title: "tools",
  //   paths: ["/tools"],
  //   disabled: true,
  //   whiteIcone: <BuildIcon style={{ color: "white" }} />,
  //   greenIcone: <BuildIcon style={{ color: "#4FD1C5" }} />,
  // },
};

export default function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { setUser } = useUser();
  const { user, updateUser, removeUser } = useStoreUser();
  const path = window.location.pathname;
  const role = localStorage.getItem("role");
  const [height, setHeight] = useState(window.innerHeight);

  const isSidebarOpen = useToggleStore((state: any) => state.isSidebarOpen);
  const toggleSidebar = useToggleStore((state: any) => state.toggleSidebar);

  const [isLockedSelected, setIsLockedSelected] = useState({
    dashboard: false,
  });

  const handleDisconnect = () => {
    setTimeout(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("clientId");
      localStorage.removeItem("role");
      setUser(undefined);

      navigate("/login");
      window.location.reload();

      localStorage.removeItem("user");
      removeUser();
    }, 300);
  };

  const navRef = useRef<HTMLDivElement>(null);

  // Function to close the navbar if clicked outside
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setIsLockedSelected({ ...isLockedSelected, dashboard: false });
    }
  };

  // Add event listener on mount, clean up on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    // Set initial height
    setHeight(window.innerHeight);

    // Add resize listener
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Nav style={{ width: isSidebarOpen ? 264 : 0, position: "relative" }}>
      <div
        onClick={toggleSidebar}
        style={{
          height: "80px",
          width: "40px",
          backgroundColor: "#84DCC6",
          position: "absolute",
          borderRadius: "0px 20px 20px 0px",
          top: "40%",
          right: "-40px",
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img
          src={ArrorSidebar}
          alt="arrow"
          style={{
            width: "30px",
            height: "30px",
            rotate: isSidebarOpen ? "0deg" : "180deg",
          }}
        />
      </div>

      {isSidebarOpen && (
        <>
          <div
            style={{
              height: height > 955 ? "100vh" : "100%",
              overflowY: height < 955 ? "auto" : "hidden",
              position: "relative",
            }}
          >
            <NavHeader>
              {isSidebarOpen && (
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    width: "100%",
                    height: "35px",
                  }}
                />
              )}

              <div
                style={{
                  height: "35px",
                }}
              ></div>
              {/* <HopleisureSubLogoDiv>{t("introduceHopbooks")}</HopleisureSubLogoDiv> */}

              <Stack direction="row" alignItems="center" spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  position={"relative"}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    src={
                      user.user?.profilePictureUrl
                        ? user.user.profilePictureUrl
                        : DefaultProfile
                    }
                    sx={{ width: 60, height: 60 }}
                  />

                  <IconButton
                    aria-label="edit"
                    color="primary"
                    sx={{ position: "absolute", right: -3, bottom: -3 }}
                  >
                    <img
                      src={ChangeUserIcon}
                      alt="change user"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </IconButton>
                </Stack>

                {isSidebarOpen && (
                  <div>
                    <NameTruncate>
                      {user?.user?.companyName || "-"}
                    </NameTruncate>
                    <EmailTruncate>
                      {user?.user?.contactEmail || "-"}
                    </EmailTruncate>
                  </div>
                )}
              </Stack>

              <Divider style={{ marginTop: "5px" }} />
            </NavHeader>
            <NavBody>
              {Object.keys(menuInfos).map((menuItem: any, index) => {
                const currentItem = menuInfos[menuItem];
                const isSelectedMenuItem = currentItem.paths.includes(path);

                if (
                  role === "Membre" &&
                  !RoleAccess.Membre.includes(menuItem)
                ) {
                  return null; // Hide the menu item
                }

                if (
                  role === "Invité" &&
                  !RoleAccess.Invité.includes(menuItem)
                ) {
                  return null; // Hide the menu item
                }

                return (
                  <ListItem key={currentItem.title} disablePadding>
                    <ListItemButton
                      style={{
                        color: isSelectedMenuItem ? "#4FD1C5" : "white",
                        backgroundColor: isSelectedMenuItem
                          ? "white"
                          : "transparent",
                        borderRadius: "10px",
                        // marginLeft: "10px",
                        // marginRight: "10px",
                        gap: "10px",
                        position: "relative",
                        zIndex: 99999999,
                      }}
                      onClick={() => {
                        if (currentItem.isLocked) {
                          setIsLockedSelected({
                            ...isLockedSelected,
                            dashboard: true,
                          });
                          return;
                        }

                        if (!currentItem.disabled)
                          navigate(currentItem.paths[0]);
                        else console.log({ disabled: currentItem.disabled });
                      }}
                    >
                      {/* <ListItemIcon> */}
                      {isSelectedMenuItem
                        ? currentItem.greenIcone
                        : currentItem.whiteIcone}
                      {/* </ListItemIcon> */}
                      {isSidebarOpen && (
                        <span
                          style={{
                            fontSize: height > 955 ? "18px" : "15px",
                            marginTop: "4px",
                            marginBottom: "4px",
                          }}
                        >
                          {t(currentItem.title)}
                        </span>

                        // <ListItemText primary={t(currentItem.title)} />
                      )}

                      {currentItem.isLocked && isSelectedMenuItem
                        ? currentItem.isLockedGreenIcone
                        : currentItem.isLockedWhiteIcone}

                      {isLockedSelected.dashboard &&
                        currentItem.title === "dashboard" && (
                          <div
                            ref={navRef}
                            style={{
                              position: "absolute",
                              right: "-150px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              backgroundColor: "white",
                              borderRadius: "10px",
                              zIndex: 99999999,
                              flexDirection: "column",
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 0",
                            }}
                          >
                            <div
                              style={{
                                color: "#878787",
                                padding: "5px",
                                fontSize: "10px",
                              }}
                            >
                              MOT DE PASSE DE SECURITE
                            </div>

                            <input
                              type="password"
                              style={{
                                width: "80%",
                                padding: "5px",
                                border: "1px solid #878787",
                                marginBottom: "5px",
                                height: "5px",
                                outline: "none",
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  if (e.currentTarget.value === "111") {
                                    setIsLockedSelected({
                                      ...isLockedSelected,
                                      dashboard: false,
                                    });
                                    navigate(currentItem.paths[0]);
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </NavBody>
            {isSidebarOpen && (
              <NavFooter
                style={{
                  // position: height > 955 ? "absolute" : "relative",
                  position: "absolute",
                  bottom: "0",
                  width: "100%",
                  // marginTop: "175px",
                }}
              >
                <LanguageSelectorWithFlag />

                <HopleisureTextButton
                  onClick={handleDisconnect}
                  style={{
                    color: "white",
                    width: "100%",
                    marginBottom: "10px",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {t("logout")}
                </HopleisureTextButton>
              </NavFooter>
            )}
          </div>
        </>
      )}
    </Nav>
  );
}

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background-color: #054643;
  color: white;
  border-radius: 0px 20px 20px 0px;
  margin-right: 30px;
  transition: width 0.3s ease-in-out;
`;

const NavHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 10px;
  margin-bottom: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    #004643 0%,
    #e0e1e2 49.52%,
    rgba(224, 225, 226, 0.15625) 99.04%
  );
`;

const HopleisureSubLogoDiv = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
`;

const NavBody = styled.div`
  // height: 100%;
  // overflow-y: auto;
`;

const NavFooter = styled.div`
  width: fit-content;
`;

const EmailTruncate = styled.div`
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NameTruncate = styled.div`
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
