import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/logoPdf.svg";
import moment from "moment";
import ReportSingleFillingRateChart from "components/report/ReportSingleFillingRateChart";
import Assistent from "components/navigation/Assistent";

const ReportSingle5 = ({
  setStatusFilling,
  title,
  actId,
}: {
  setStatusFilling: (value: boolean) => void;
  title: string;
  actId: string;
}) => {
  const { t } = useTranslation("dashboard");
  const [searchParams] = useSearchParams();
  const startDate: string | null = searchParams.get("startDate");
  const endDate: string | null = searchParams.get("endDate");

  return (
    <div style={{ width: "210mm", minHeight: "297mm" }}>
      <Navbar title={t("evolutionFillingRate")} />
      <Assistent />
      <div style={{ padding: "0px 30px", marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <ActivityColumn>
            <p style={{ fontSize: 14 }}>
              {t("activity")} :{" "}
              <span style={{ color: "#2CA58D" }}>
                {title} du {moment(startDate).format("YYYY/MM/DD")} au{" "}
                {moment(endDate).format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </ActivityColumn>
        </div>
        <ActivityContent marginBottom="10px">
          <ReportSingleFillingRateChart
            t={t}
            startDate={startDate}
            endDate={endDate}
            activitiesId={actId}
            getStatus={(value) => {
              setStatusFilling(value);
            }}
          />
        </ActivityContent>
        <ActivityContent>
          <CardWrapper>
            <p style={{ fontWeight: 800, fontSize: 12 }}>
              {t("titleChartFillingRate")} :{" "}
              <span style={{ fontWeight: 400 }}>
                {t("descReportFilligRate")}
              </span>
            </p>
          </CardWrapper>
        </ActivityContent>
      </div>
    </div>
  );
};

const Navbar = (props: { title: string }) => {
  const { title } = props;
  return (
    <div>
      <div
        style={{
          backgroundColor: "#004346",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <img alt="logo" src={logo} style={{ width: 100 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: 400,
            fontSize: 30,
            height: 85,
            width: "100%",
            marginRight: 30,
          }}
        >
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

const ActivityContent = styled.div<{ marginBottom?: string }>`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "30px"};
`;

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const CardWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  border-radius: 30px;
  padding: 0px 15px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

export default ReportSingle5;
