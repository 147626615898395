import axios from "axios";
import { getAuthorizedConfig } from "../../helpers/helpers";

export async function getUsers(): Promise<any> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/users`;
  const response = await axios.request(config);
  return response.data;
}

export async function updateUsers(updateData: any): Promise<any> {
  let config = getAuthorizedConfig("PUT");
  config["url"] = `${process.env.REACT_APP_API_URL}/users/${updateData.id}`;
  config["data"] = updateData;
  const response = await axios.request(config);
  return response.data;
}

export async function createUsers(data: any): Promise<any> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/users`;
  config["data"] = data;
  const response = await axios.request(config);
  return response.data;
}

export async function deleteUsers(data: any): Promise<any> {
  let config = getAuthorizedConfig("PATCH");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/users/${data?.id}/setUserStatus`;
  config["data"] = { status: data?.status };
  const response = await axios.request(config);
  return response.data;
}

export async function getUserMe(): Promise<any> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/users/me`;
  const response = await axios.request(config);
  return response.data;
}
