import React from "react";
import "./ChannelCard.css";
import { ChannelCardPropsType } from "./ChannelCard.types ";
import { arePropsEqual } from "helpers/helpers";

const ChannelCard = (props: ChannelCardPropsType) => {
  const { channel, tabSelectFct, isActive } = props;

  const handleClick = () => {
    tabSelectFct(channel);
  };

  return (
    <div
      className={`website-channel ${isActive && "active"}`}
      tabIndex={0} // Allows focus with keyboard
      onClick={handleClick}
    >
      <span className="website-channel-icon">
        {React.createElement(channel.icon, {
          className: "website-channel-icon",
          style: { fontSize: "3rem" },
        })}
      </span>
      <span className="website-channel-name">{channel.name}</span>
      <span className="website-channel-label">{channel.label}</span>
    </div>
  );
};

export default React.memo(ChannelCard, arePropsEqual);
