import { useState } from "react";
import { Modal, Button, Typography, Box, Paper, styled } from "@mui/material";

const PreviewBox = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 850,
  maxHeight: "90vh",
  overflow: "auto",
  backgroundColor: "#fff",
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[24],
}));

const ModalHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: "1px solid #e0e0e0",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const ModalFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: "1px solid #e0e0e0",
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
}));

const PreviewContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: "#fff",
  borderRadius: theme.spacing(1),
}));

const GreenText = styled(Typography)(({ theme }) => ({
  color: "#2E8B57",
  marginBottom: theme.spacing(2),
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#1B4D3E",
  color: "#fff",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const InfoSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#F8F9FA",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export default function ModalPreview({
  t,
  type,
  open,
  content,
  filename,
  handleClose,
  isLoading,
  onSubmit,
}: {
  t: any;
  type: string;
  open: boolean;
  content?: string;
  filename?: string;
  handleClose: () => void;
  isLoading?: boolean;
  onSubmit: () => void;
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="email-preview-modal"
      aria-describedby="email-preview-description"
    >
      <PreviewBox>
        <ModalHeader style={{ borderBottom: "0" }}>
          <Typography
            variant="h6"
            id="email-preview-modal"
            style={{
              color: "#2CA58D",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("modalPreview.title")}
          </Typography>
        </ModalHeader>

        <ModalContent>
          <Typography
            variant="body2"
            sx={{ mb: 2 }}
            id="email-preview-description"
            style={{
              color: "#004346",
              fontWeight: "bold",
              fontStyle: "italic",
              fontSize: "16px",
            }}
          >
            {t("modalPreview.hereIsTheContent")}
            <span
              style={{
                fontWeight: "400",
              }}
            >
              {t("modalPreview.emailSmsRappel")}
            </span>
          </Typography>

          <Typography
            variant="body2"
            sx={{ mb: 2 }}
            id="email-preview-description"
            style={{
              color: "#004346",
              fontWeight: "bold",
              fontStyle: "italic",
              fontSize: "16px",
            }}
          >
            {t("modalPreview.theSubject")}
          </Typography>

          <Typography
            variant="body2"
            sx={{ mb: 2 }}
            id="email-preview-description"
            style={{
              color: "#004346",
              fontWeight: "bold",
              fontStyle: "italic",
              fontSize: "16px",
            }}
          >
            {t("modalPreview.youCanInsert")}
            <span
              style={{
                color: "#2CA58D",
              }}
            >
              {t("modalPreview.thisPersonalized")}
            </span>
          </Typography>

          <PreviewContent elevation={1}>
            <HeaderBox>
              {type === "Rappel" ? (
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  {t("modalPreview.rappelReservation")}
                </Typography>
              ) : (
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  {t("modalPreview.demandeReservation")}
                </Typography>
              )}
            </HeaderBox>

            <Typography variant="body1" sx={{ mb: 2, fontSize: "18px" }}>
              {t("modalPreview.hallo")} Thomas Levacancier,
            </Typography>

            <GreenText
              variant="body1"
              sx={{ mb: 2, fontSize: "18px", color: "#2CA58D" }}
            >
              {content}
            </GreenText>

            {type === "Rappel" && (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {t("modalPreview.rappel.bookingDetail")}
                </Typography>

                <Box sx={{ ml: 3, p: 0 }}>
                  <Typography sx={{ mb: 1 }}>
                    &#8226;
                    <strong style={{ marginLeft: "8px" }}>
                      {t("modalPreview.rappel.activity")}
                    </strong>{" "}
                    {t("modalPreview.rappel.valueActivity")}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    &#8226;
                    <strong style={{ marginLeft: "8px" }}>
                      {t("modalPreview.rappel.date")}
                    </strong>{" "}
                    {t("modalPreview.rappel.valueDate")}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    &#8226;
                    <strong style={{ marginLeft: "8px" }}>
                      {t("modalPreview.rappel.address")}
                    </strong>{" "}
                    {t("modalPreview.rappel.valueAddress")}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    &#8226;
                    <strong style={{ marginLeft: "8px" }}>
                      {t("modalPreview.rappel.number")}
                    </strong>{" "}
                    {t("modalPreview.rappel.valueNumber")}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    &#8226;
                    <strong style={{ marginLeft: "8px" }}>
                      {t("modalPreview.rappel.total")}
                    </strong>{" "}
                    {t("modalPreview.rappel.valueTotal")}
                  </Typography>
                </Box>
              </>
            )}

            <InfoSection sx={{ backgroundColor: "#f0f8ff" }}>
              <Typography sx={{ mb: 1, fontSize: "19px", fontWeight: "bold" }}>
                {t("modalPreview.establishmentContact")}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                {t("modalPreview.email")}{" "}
                {t("modalPreview.establishmentValueEmail")}
              </Typography>
              <Typography sx={{ mb: 2 }}>
                {t("modalPreview.establishmentTelephone")}{" "}
                {t("modalPreview.establishmentValueTelephone")}
              </Typography>
              <Typography sx={{ mb: 1, fontSize: "19px", fontWeight: "bold" }}>
                {t("modalPreview.yourContact")}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                {t("modalPreview.yourEmail")} {t("modalPreview.yourValueEmail")}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                {t("modalPreview.yourTelephone")}{" "}
                {t("modalPreview.yourValueTelephone")}
              </Typography>
            </InfoSection>

            <Typography
              sx={{
                mt: 3,
                textAlign: "center",
                color: "#666",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              {t("modalPreview.haveANiceDay")}
            </Typography>
            {type === "Rappel" && (
              <Typography
                sx={{
                  mt: 1,
                  textAlign: "center",
                  color: "#666",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {t("modalPreview.rappel.desc")}
              </Typography>
            )}
          </PreviewContent>
          <Typography
            sx={{
              mt: 2,
              ml: 2,
              color: "#004346",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            {t("modalPreview.attachment")} {filename}
          </Typography>
        </ModalContent>

        <ModalFooter style={{ borderTop: "0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="info"
              onClick={handleClose}
              sx={{
                borderRadius: "30px",
                padding: "8px 30px",
                backgroundColor: "#FFF3D6",
                color: "#8B4513",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: `
                  0px 4px 8px rgba(0, 0, 0, 0.1),
                  0px -2px 4px rgba(255, 255, 255, 0.9),
                  0px 2px 4px -1px rgba(0, 0, 0, 0.15),
                  inset 0px 2px 4px rgba(255, 255, 255, 0.8)
                `,
                "&:hover": {
                  backgroundColor: "#FFE9B8",
                  border: "none",
                  boxShadow: `
                    0px 4px 10px rgba(0, 0, 0, 0.15),
                    0px -2px 4px rgba(255, 255, 255, 0.9),
                    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                    inset 0px 2px 4px rgba(255, 255, 255, 0.8)
                  `,
                },
                "&:active": {
                  boxShadow: `
                    0px 2px 4px rgba(0, 0, 0, 0.1),
                    inset 0px 2px 4px rgba(0, 0, 0, 0.1)
                  `,
                  backgroundColor: "#FFE9B8",
                  border: "none",
                },
              }}
            >
              {t("modalPreview.buttonSecondary")}
            </Button>
            <Button
              variant="contained"
              color="info"
              sx={{
                borderRadius: "20px",
                padding: "5px 25px",
              }}
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t("modalPreview.buttonPrimary")}
            </Button>
          </div>
        </ModalFooter>
      </PreviewBox>
    </Modal>
  );
}
