import FileCopyIcon from "@mui/icons-material/FileCopy";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import MainTitle from "components/general/MainTitle";
import Navbar from "components/navigation/Navbar";
import ProfileInformations from "components/profile/profileInformations";
import ProfileSettings from "components/profile/profileSettings";
import ProfileTeam from "components/profile/profileTeam";
import StripeConnect from "components/profile/StripeConnect";
import { theme } from "helpers/constants";

import { driver } from "driver.js";
import { useEffect } from "react";
import { renderToString } from "react-dom/server";
import Assistent from "components/navigation/Assistent";

export default function Profile() {
  const navigate = useNavigate();

  const { t } = useTranslation("profile");
  const { t: generalT } = useTranslation("general");

  // const { user } = useUser();

  const currentSection: "general" | "invoicing" | "emailing" =
    window.location.pathname === "/profile"
      ? "general"
      : window.location.pathname === "/facturation"
        ? "invoicing"
        : "emailing";

  const NAVIGATIONS = [
    {
      title: "GENERAL",
      path: `/profile`,
      icon: (
        <WidgetsIcon
          sx={{
            color:
              window.location.pathname === "/profile"
                ? "white"
                : theme.color.primary,
            mr: 1,
          }}
        />
      ),
    },
    {
      title: generalT("invoicing"),
      path: `/facturation`,
      icon: (
        <FileCopyIcon
          sx={{
            color:
              window.location.pathname === "/facturation"
                ? "white"
                : theme.color.primary,
            mr: 1,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "Membre" || !role) {
      navigate("/agenda");
    } else {
      const driverObj = driver({
        showProgress: true,
        showButtons: ["next"],
        // nextBtnText: renderToString(
        //   <div>
        //     Suivant
        //   </div>
        // ),
        steps: [
          {
            element: "#tour-profile",
            popover: {
              title: "",
              description: renderToString(
                <div>
                  Bienvenue chez HoPleisure Avant de créer vos premières
                  activités, il est nécessaire de renseigner votre profil.
                  <button>Suivant</button>
                </div>
              ),
            },
          },
          {
            element: "#tour-profile-contact",
            popover: {
              title: "Step 2",
              description: "Lorem ipsum dolor amet",
            },
          },
          {
            element: "#tour-profile-card",
            popover: {
              title: "Step 3",
              description: "Lorem ipsum dolor amet",
            },
          },
          {
            element: "#tour-profile-team",
            popover: {
              title: "Step 4",
              description: "Lorem ipsum dolor amet",
            },
          },
        ],
      });

      // driverObj.drive();
    }
  }, []);

  return (
    <Page>
      <Navbar />
      <Assistent />
      <Container>
        <MainTitle navigations={NAVIGATIONS} title={t("title")} />

        {currentSection === "general" && (
          <>
            <ProfileSettings t={t} id="tour-profile-contact" />
            <ProfileTeam t={t} id="tour-profile-card" />
            {/* <TeamAlert id="tour-profile-team" /> */}
          </>
        )}
        {currentSection === "invoicing" && (
          <>
            <ProfileInformations t={t} />
            <StripeConnect t={t} />
          </>
        )}
        {/* {currentSection === "emailing" && (
          <>
            <EmailingPage />
            <EmailingActivies />
          </>
        )} */}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  background-color: #e0e1e2;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
`;
