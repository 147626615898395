import { MainPageCard } from "helpers/generalStyles";
import { ActivityForm } from "pages/Activities";
import React, { Dispatch, SetStateAction } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { emptyActivity } from "helpers/constants";
import ActivityType from "../ActivityType";
import { arePropsEqual } from "helpers/helpers";

const ActivitiesHeaderCard = (props: {
  activity: ActivityForm | undefined;
  setActivity: Dispatch<SetStateAction<ActivityForm | undefined>>;
  setActivities: Dispatch<SetStateAction<ActivityForm[]>>;
  activities: ActivityForm[];
}) => {
  const { activity, setActivity, setActivities, activities } = props;

  return (
    <MainPageCard style={{ minHeight: "105px" }}>
      <AddCircleOutlineIcon
        fontSize="large"
        style={{
          color: "#4FD1C5",
          cursor: "pointer",
          position: "absolute",
          top: -15,
          right: -10,
        }}
        onClick={() => {
          const containAlreadyANewActivity = activities.find(
            (item) => !item.id
          );
          if (!containAlreadyANewActivity) {
            setActivities([...activities, emptyActivity]);
            setActivity(emptyActivity);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          overflowY: "scroll",
          height: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {activities.map((activityElem, index) => (
          <React.Fragment key={index}>
            {!activityElem.informations?.isDeleted && (
              <ActivityType
                key={index}
                id={activityElem?.id}
                informations={activityElem.informations}
                selected={
                  activity !== undefined && activity?.id === activityElem?.id
                }
                disabled={!activityElem.informations.isEnabled}
                onClick={() => {
                  setActivity(activityElem);
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </MainPageCard>
  );
};

export default React.memo(ActivitiesHeaderCard, arePropsEqual);
