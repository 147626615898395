const emailing = {
    en: {
        cardLeft: {
            title: 'MY TEMPLATES',
            titleForm: 'SEND SETTINGS',
            titleContent: 'TEMPLATE TEXT',

            labelName: 'Template Name',
            labelType: 'Trigger event for sending',
            labelHours: 'Setting the sending time',
            desc: 'Number of hours to send before booking (for reminder) or after booking (for review request)',

            placeholderTextarea: 'Please fill in the text below',
            inputFile: 'Attachment',

            templateType1: "Reminder prior to the activity",
            templateType2: "Review request after the activity",

            buttonSecondary: 'TEMPLATE PREVIEW',
            buttonPrimary: 'SAVE TEMPLATE'
        },

        cardRight: {
            title: 'CHOICE OF TEMPLATES BY ACTIVITY',
            checkboxEmail: 'Send by email',
            checkboxSMS: 'Send by SMS',

            titleHead1: 'Activity',
            titleHead2: 'Reminder',
            titleHead3: "Request for advice",

            desc: 'If no template is associated with the activity for the event in question, no message will be sent when the event occurs.',
            buttonPrimary: 'SAVE TEMPLATES FOR EACH ACTIVITY'
        },

        modalDelete: {
            title: "DO YOU WANT TO DELETE THIS TEMPLATE?",
            desc: "Please note that this action is irreversible. Activities for which this template is active will no longer have an active template and your customers will no longer receive alerts.",
            buttonSecondary: 'CONFIRM DELETION',
            buttonPrimary: 'BACK'
        },

        modalPreview: {
            title: "AUTOMATIC EMAIL PREVIEW",

            hereIsTheContent: "Here is the content of the message that your clients will receive for the selected event:",
            emailSmsRappel: "Reminder Email/SMS.",

            theSubject: "The subject, greeting and closing phrases, as well as reservation details (schedule, contact information, selected activity, chosen rates...) are automatically generated by HoPleisure.",
            youCanInsert: "You can insert a personalized message in the email body, but be careful not to include specific reservation details as this template will be reused for all messages!",
            thisPersonalized: "This personalized message appears in green in the preview.",

            rappelReservation: "Reservation Reminder : Activity A",
            demandeReservation: "Leave us a review : Activity A",
            hallo: "Hello ",

            establishmentContact: "Establishment contact details :",
            establishmentEmail: "Email:",
            establishmentValueEmail: "escapegame.domicile@gmail.com",
            establishmentTelephone: "Phone:",
            establishmentValueTelephone: "06 52 52 52 52",

            yourContact: "Your contact details:",
            yourEmail: "Email:",
            yourValueEmail: "thomaslevacancier@gmail.com",
            yourTelephone: "Phone:",
            yourValueTelephone: "06 18 18 18 18",

            haveANiceDay: "Have a nice day and see you soon.",

            rappel: {
                bookingDetail: "Here are the booking details :",

                activity: "Activities :",
                valueActivity: "murder party 2",

                date: "Date and Time:",
                valueDate: "2024-06-11 09:00 to 2024-06-11 11:00",

                address: "Address:",
                valueAddress: "rue de la Vie, Montgeron, France",

                number: "Number of seats :",
                valueNumber: "1",

                total: "Total Price :",
                valueTotal: "12.00 €",

                desc: "This experience is offered for booking by HoPleisure SAS, registered under the SIREN number 952 553 139, acting on behalf of and for the account of the company ${client_company_name}, registered under the SIREN number ${client_siren}, in accordance with the mandate entrusted to it.",
            },
            attachment: "Attachment:",

            buttonSecondary: "BACK TO EDITION",
            buttonPrimary: "SAVE THE TEMPLATE"
        },

        EMAIL_TEMPLATE_UPDATED: "Email template updated",
        EMAIL_TEMPLATE_CREATED: "Email template created",
        EMAIL_TEMPLATE_DELETED: "Email template deleted",

        ACTIVITY_EMAIL_TEMPLATE_UPDATED: "Activity email template updated"
    },
    fr: {
        cardLeft: {
            title: 'MES TEMPLATES',
            titleForm: "PARAMÈTRES DE L'ENVOI",
            titleContent: 'TEXTE DU TEMPLATE',

            labelName: "Nom du template",
            labelType: 'Évènement déclencheur de l’envoi',
            labelHours: 'Paramétrage de l’heure d’envoi',
            desc: 'Nombre d’heures pour l’envoi avant la réservation (pour le rappel) ou après la réservation (pour la demande d’avis)',

            placeholderTextarea: 'Veuillez remplir le texte ci-dessous',
            inputFile: 'Pièce-jointe',

            templateType1: "Rappel préalable à l’expérience",
            templateType2: "⁠Demande d’avis postérieure à l’expérience",

            buttonSecondary: 'APERÇU DU TEMPLATE',
            buttonPrimary: 'ENREGISTRER LE TEMPLATE',
        },

        cardRight: {
            title: 'CHOIX DES TEMPLATES PAR ACTIVITÉ',
            checkboxEmail: 'Envoi par email',
            checkboxSMS: 'Envoi par SMS',

            titleHead1: 'Activité',
            titleHead2: 'Rappel',
            titleHead3: "Demande d'avis",

            desc: "Si aucun template n'est associé à l'activité pour l'événement concerné, aucun message ne sera envoyé lors de la survenance de l'événement.",
            buttonPrimary: 'SAUVEGARDER LES TEMPLATES POUR CHAQUE ACTIVITÉ'
        },

        modalDelete: {
            title: "SOUHAITEZ-VOUS SUPPRIMER CE TEMPLATE ?",
            desc: "Attention, cette action est irréversible. Les activités pour lesquels ce template est actif, n’auront plus de template actif et vos clients en recevront plus d’alertes.",
            buttonSecondary: 'CONFIRMER LA SUPPRESSION',
            buttonPrimary: 'RETOUR'
        },

        modalPreview: {
            title: "PREVISUALISATION DE L’EMAIL AUTOMATIQUE",

            hereIsTheContent: 'Voici le contenu du message que recevrons vos clients pour l’évènement sélectionné :',
            emailSmsRappel: 'Email/SMS de rappel.',

            theSubject: "L’objet, les formules de politesse initiales et de clotûre ainsi que les détails de la réservation (horaires, coordonnées, activité choisi, tarifs choisis...) sont automatiquement générés par HoPleisure.",
            youCanInsert: "Vous pouvez insérer un passage personnalisé grâce au corps de l’email mais veillez à ne pas inclure d’informations spécifiques à une réservation car ce template sera réutilisé lors de tous les envois !",
            thisPersonalized: "Ce message personnalisé apparait en vert dans la prévisualisation.",

            rappelReservation: 'Rappel de réservation : Activité A',
            demandeReservation: 'Laissez-nous un avis : Activité A',
            hallo: 'Bonjour ',

            establishmentContact: "Coordonnées de l'établissement :",
            establishmentEmail: 'Email :',
            establishmentValueEmail: 'escapegame.domicile@gmail.com',
            establishmentTelephone: 'Téléphone :',
            establishmentValueTelephone: '06 52 52 52 52',

            yourContact: 'Vos coordonnées :',
            yourEmail: 'Email :',
            yourValueEmail: 'thomaslevacancier@gmail.com',
            yourTelephone: 'Téléphone :',
            yourValueTelephone: '06 18 18 18 18',

            haveANiceDay: 'Bonne journée et à très vite.',

            rappel: {
                bookingDetail: 'Voici les détails de la réservation :',

                activity: 'Activités :',
                valueActivity: 'murder party 2',

                date: 'Date et heure :',
                valueDate: '2024-06-11 09:00 to 2024-06-11 11:00',

                address: 'Adresse :',
                valueAddress: 'rue de la Vie, Montgeron, France',

                number: 'Nombre de places :',
                valueNumber: '1',

                total: 'Prix total :',
                valueTotal: '12.00 €',

                desc: 'Cette expérience vous est proposée à la réservation par HoPlaisure SAS, enregistrée sous le numéro SIREN 952 553 139, agissant au nom et pour le compte de la société ${client_company_name}, enregistrée sous le numéro SIREN ${client_siren} conformément au mandat qui lui a été confié.',
            },
            attachment: 'Pièce-jointe :',

            buttonSecondary: 'RETOUR A L’EDITION',
            buttonPrimary: 'ENREGISTRER LE TEMPLATE'
        },

        EMAIL_TEMPLATE_UPDATED: "Modèle d'e-mail mis à jour",
        EMAIL_TEMPLATE_CREATED: "Modèle d'e-mail créé",
        EMAIL_TEMPLATE_DELETED: "Modèle d'e-mail supprimé",

        ACTIVITY_EMAIL_TEMPLATE_UPDATED: "Modèle d'e-mail d'activité mis à jour"
    },
}

export default emailing