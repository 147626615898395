import { Box, Button, Modal, Paper, SelectChangeEvent } from "@mui/material";
import { getAllClientActivitiesNameAndIds } from "backend/api/dashboard";
import {
  createUsers,
  deleteUsers,
  getUsers,
  updateUsers,
} from "backend/api/profile";
import AlertMessage from "components/general/AlertMessage";
import { MainPageCard } from "helpers/generalStyles";
import { Message } from "helpers/helpers";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import CardNewUser from "./cardsProfile/cardNewUser";
import CardUser from "./cardsProfile/cardUser";
import ResponseError from "hooks/ResponseError";

type Props = {
  t: any;
  id: string;
};

const ProfileTeam = ({ t, id }: Props) => {
  const { t: tProfile } = useTranslation("profile");
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const fileInputRef = useRef(null);
  const fileInputRefNew = useRef(null);

  const [idUser, setIdUser] = useState("");
  const [avatar, setAvatar] = useState("");
  const [fileAvatar, setFileAvatar] = useState("");

  const [mimeType, setMimeType] = useState("");
  const [base64, setBase64] = useState("");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);
  const [smsAlert, setSmsAlert] = useState(false);
  const [selectedActivityIds, setSelectedActivityIds] = useState<
    Array<string | number>
  >([]);

  const [isEdit, setIsEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [dataList, setDataList] = useState<any>([]);

  const [dataActivities, setDataActivities] = useState<any>([]);

  useQuery({
    queryKey: ["getAllClientActivitiesNameAndIds"],
    queryFn: () => getAllClientActivitiesNameAndIds(),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setDataActivities(data);
    },
  });

  const { data: dataUsers, refetch: refetchUsers } = useQuery(
    "users",
    getUsers,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const newData = data.filter((item: any) => item.status !== "DELETED");
        const newResult = newData.map((item: any) => {
          return {
            ...item,
            activities: item.activities
              ? item.activities.map((activity: any) => activity?.toString())
              : [],
            isLockerOpen: false,
          };
        });
        setDataList(newResult);
      },
      onError: (error) => {
        console.log(error);
        ResponseError(error);
      },
    }
  );

  const { mutate: mutateCreateUser } = useMutation({
    mutationFn: createUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);
      handleResetField();

      setAlertMessage({ type: "success", message: tProfile("USER_CREATED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const { mutate: mutateUpdateUser } = useMutation({
    mutationFn: updateUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);

      setAlertMessage({ type: "success", message: tProfile("USER_UPDATED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const { mutate: mutateDeleteUser } = useMutation({
    mutationFn: deleteUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      handleResetField();
      handleCloseModalDelete();

      setAlertMessage({ type: "success", message: tProfile("USER_DELETED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const handleSelectChange = (
    event: SelectChangeEvent<typeof selectedActivityIds>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedActivityIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLockerOpen = (v: any) => {
    handleFillField(v);
    setIsEdit(false);

    const newDataList = dataList.map((item: any) => {
      if (item.id === v.id) {
        return {
          ...item,
          isLockerOpen: !item.isLockerOpen,
        };
      } else {
        return {
          ...item,
          isLockerOpen: false,
        };
      }
    });
    setDataList(newDataList);
  };

  const handleOpenModalDelete = (v: any) => {
    handleFillField(v);
    setIsEdit(false);

    setOpenModalDelete(true);

    const newDataList = dataList.map((item: any) => ({
      ...item,
      isLockerOpen: false,
    }));

    setDataList(newDataList);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
    handleResetField();
    setOpenModalDelete(false);
  };

  const handleResetField = () => {
    setAvatar("");
    setFileAvatar("");
    setMimeType("");
    setBase64("");
    setIdUser("");
    setFullName("");
    setEmail("");
    setPhone("");
    setRole("");
    setEmailAlert(false);
    setSmsAlert(false);
    setSelectedActivityIds([]);
    setIsEdit(false);
  };

  const handleFillField = (v: any) => {
    setAvatar("");
    setFileAvatar("");
    setMimeType("");
    setBase64("");
    setIdUser(v.id);
    setFullName(v.fullName);
    setEmail(v.email);
    setPhone(v.phoneNumber);
    setRole(v.role);
    setEmailAlert(v.emailAlert);
    setSmsAlert(v.smsAlert);
    setSelectedActivityIds(
      v.activities.map((id: any) =>
        typeof id === "string" ? id : id.toString()
      )
    );
  };

  return (
    <MainPageCard id={id}>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={tProfile}
      />

      <Modal open={openModalDelete} onClose={handleCloseModalDelete}>
        <Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "26px",
              padding: 0,
              textAlign: "center",
            }}
          >
            ÊTES-VOUS CERTAIN DE VOULOIR SUPPRIMER <br /> CET UTILISATEUR ?
          </Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "20px",
              padding: 0,
              textAlign: "center",
              mt: "15px",
              mb: "25px",
            }}
          >
            Il n’aura plus accès à votre compte sur https://app.hopleisure.com
          </Box>

          <Box
            sx={{
              padding: 0,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
              gap: "15px",
              width: "70%",
            }}
          >
            <Button
              sx={{
                bgcolor: "#2CA58D",
                borderRadius: "30px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#248370",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              onClick={() =>
                mutateDeleteUser({
                  id: idUser,
                  status: "DELETED",
                })
              }
            >
              OUI SUPPRIMER L’UTILISATEUR
            </Button>

            <Button
              sx={{
                bgcolor: "#FFE0764A",
                borderRadius: "30px",
                color: "#7E4501",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#f9ca3048",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              onClick={handleCloseModalDelete}
            >
              NON, REVENIR EN ARRIÈRE
            </Button>
          </Box>
        </Box>
      </Modal>

      <Title>{t("titleTeam").toUpperCase()}</Title>

      <Box
        sx={{
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            "&::-webkit-scrollbar": {
              height: "0.4em",
            },
            // '&::-webkit-scrollbar-track': {
            //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            // },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.1)",
            },
            boxShadow: "none",
            border: "none",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {dataList.map((item: any, index: number) => (
              <CardUser
                key={index}
                t={tProfile}
                item={item}
                handleOpenModalDelete={handleOpenModalDelete}
                handleLockerOpen={handleLockerOpen}
                id={id}
                avatar={avatar}
                setAvatar={setAvatar}
                setFileAvatar={setFileAvatar}
                mimeType={mimeType}
                setMimeType={setMimeType}
                base64={base64}
                setBase64={setBase64}
                fullName={fullName}
                setFullName={setFullName}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                role={role}
                setRole={setRole}
                emailAlert={emailAlert}
                setEmailAlert={setEmailAlert}
                smsAlert={smsAlert}
                setSmsAlert={setSmsAlert}
                selectedActivityIds={selectedActivityIds}
                setSelectedActivityIds={setSelectedActivityIds}
                handleSelectChange={handleSelectChange}
                fileInputRef={fileInputRef}
                dataActivities={dataActivities}
                onSave={() => {
                  let payload: {
                    id: string;
                    fullName: string;
                    email: string;
                    phoneNumber: string;
                    role: string;
                    emailAlert: boolean;
                    smsAlert: boolean;
                    activities: number[];
                    profilePicture?: {
                      mimeType: string;
                      base64File: string;
                    };
                  } = {
                    id: idUser,
                    fullName,
                    email,
                    phoneNumber: phone,
                    role,
                    emailAlert,
                    smsAlert,
                    activities: selectedActivityIds.map((id) =>
                      parseInt(id as string)
                    ),
                    profilePicture: {
                      mimeType: mimeType || "",
                      base64File: base64.split(",")[1] || "",
                    },
                  };

                  mutateUpdateUser(payload);
                }}
              />
            ))}

            <CardNewUser
              id={id}
              t={tProfile}
              setIsEdit={(v) => {
                handleResetField();
                const newDataList = dataList.map((item: any) => ({
                  ...item,
                  isLockerOpen: false,
                }));
                setDataList(newDataList);
                setIsEdit(v);
              }}
              isEdit={isEdit}
              avatar={avatar}
              setAvatar={setAvatar}
              setFileAvatar={setFileAvatar}
              mimeType={mimeType}
              setMimeType={setMimeType}
              base64={base64}
              setBase64={setBase64}
              fullName={fullName}
              setFullName={setFullName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              role={role}
              setRole={setRole}
              emailAlert={emailAlert}
              setEmailAlert={setEmailAlert}
              smsAlert={smsAlert}
              setSmsAlert={setSmsAlert}
              selectedActivityIds={selectedActivityIds}
              setSelectedActivityIds={setSelectedActivityIds}
              handleSelectChange={handleSelectChange}
              fileInputRef={fileInputRefNew}
              dataActivities={dataActivities}
              onSave={() => {
                let payload: any = {
                  fullName,
                  email,
                  phoneNumber: phone,
                  role,
                  emailAlert,
                  smsAlert,
                  activities: selectedActivityIds.map((id) =>
                    parseInt(id as string)
                  ),
                  profilePicture: {
                    mimeType: mimeType || "",
                    base64File: base64.split(",")[1] || "",
                  },
                };

                mutateCreateUser(payload);
              }}
            />
          </Box>
        </Paper>
      </Box>
    </MainPageCard>
  );
};

export default ProfileTeam;

const Title = styled.div`
  padding-top: 30px;
  color: #2ca58d;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
`;
