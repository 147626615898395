import React from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Box } from "@mui/material";
import { PostListReservationsOutput } from "backend/api/dashboard";
import {
  activityCategories,
  initialListReservationData,
} from "helpers/constants";
import {
  capitalizeFirstLetter,
  formaterEuro,
  formatTarifs,
} from "helpers/helpers";
import { Oval } from "react-loader-spinner";

interface Column {
  id: string | number;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const getColumn = (t: any) => {
  const columns: readonly Column[] = [
    { id: 0, label: "# ID", align: "center", minWidth: 100 },
    {
      id: 1,
      label: "Date",
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: 2,
      label: "Booking Date",
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toLocaleString("en-US"),
    },
    // {
    //   id: 3,
    //   label: `${t("price")} (TTC) Client final`,
    //   align: "center",
    //   minWidth: 100,
    //   // format: (value: number) => value.toLocaleString("en-US"),
    // },
    {
      id: 4,
      label: `${t("activity")}`,
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 5,
      label: "Client",
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 6,
      label: "Tarifs",
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 7,
      label: t("platform"),
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 8,
      label: t("statusReservation"),
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 9,
      label: t("platformFee"),
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 10,
      // label: `CA TTC net com`,
      label: t("totalRevenue"),
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
    {
      id: 11,
      label: t("tva").toUpperCase(),
      align: "center",
      minWidth: 100,
      // format: (value: number) => value.toFixed(2),
    },
  ];

  return columns;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2CA58D",
    color: "white",
    border: "1px solid #1A3636",
    lineHeight: 1.5,
  },
  [`&.${tableCellClasses.body}`]: {
    borderRight: "1px solid #1A3636",
    borderLeft: "1px solid #1A3636",
    borderBottom: "none",
    textAlign: "center",
    fontSize: 13,
  },
}));

const ReservationList = (props: {
  t: any;
  isLoading: boolean;
  listReservationData: PostListReservationsOutput;
}) => {
  const { t, isLoading, listReservationData } = props;

  return (
    <MainPageCard>
      <TitleChart>
        <h2 style={{ color: "#2CA58D" }}>
          {t("titleListReservation").toUpperCase()}
        </h2>
      </TitleChart>
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Oval width={100} height={400} />
        </div>
      )}
      {!isLoading && listReservationData.result.length > 0 && (
        <Box
          sx={{
            width: "92%",
            display: "table",
            tableLayout: "fixed",
            backgroundColor: "transparent",
          }}
        >
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <TableContainer
              sx={{
                maxHeight: 800,
                "&::-webkit-scrollbar": {
                  width: 10,
                  height: 8,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgb(225, 249, 243)",
                  borderRadius: 4,
                },
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ background: "#2CA58D" }}>
                  <TableRow>
                    {getColumn(t).map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontSize: 14,
                          padding: "5px 16px",
                        }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listReservationData.result.map((data, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{data.id}</StyledTableCell>
                      <StyledTableCell>
                        {data.date
                          ? moment(data.date).format("DD/MM/YYYY")
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data.bookingDate
                          ? moment(data.bookingDate).format("DD/MM/YYYY")
                          : "-"}
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        {formaterEuro(data.prix ?? 0)}
                      </StyledTableCell> */}
                      <StyledTableCell>
                        {/* {data.activity
                          ? t(`${activityCategories[data.activity]}`)
                          : "-"} */}
                        {data.activity}
                      </StyledTableCell>
                      <StyledTableCell>{data.client ?? "-"}</StyledTableCell>
                      <StyledTableCell>
                        {data.tarifs ? data.tarifs.join("; ") : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data.platform
                          ? capitalizeFirstLetter(data.platform)
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>{data.status ?? "-"}</StyledTableCell>
                      <StyledTableCell>
                        {formaterEuro(data.com ?? 0) ?? 0}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formaterEuro(data.ca ?? 0)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formaterEuro(data.tva ?? 0)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
    </MainPageCard>
  );
};

export const MainPageCard = styled.div`
  width: 75%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #bababa;
  box-shadow: 5px 5px 11px -3px #666666;
`;

const TitleChart = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -15px;
`;
export default ReservationList;
