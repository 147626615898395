import { Close, Edit } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  Avatar,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { InputMultiSelect, IOSSwitchLabel } from "components/formInputs/Inputs";
import DefaultProfile from "images/default-profile.png";
import { MuiTelInput } from "mui-tel-input";
import React, { useRef, useState } from "react";

interface CardNewUserProps {
  dataActivities: { id: string; name: string }[];
  onSave: (item: any) => void;

  isEdit?: boolean;
  setIsEdit: (value: boolean) => void;

  id: string;
  t?: (key: string) => string;
}

const CardNewUser: React.FC<CardNewUserProps> = ({
  dataActivities,
  onSave,
  isEdit = false,
  setIsEdit,
  id,
  t = (key) => key,
}) => {
  const [avatar, setAvatar] = useState("");
  const [fileAvatar, setFileAvatar] = useState<File | null>(null);
  const [mimeType, setMimeType] = useState("");
  const [base64, setBase64] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);
  const [smsAlert, setSmsAlert] = useState(false);
  const [selectedActivityIds, setSelectedActivityIds] = useState<
    (string | number)[]
  >([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedActivityIds(event.target.value as string[]);
  };

  const handleSave = () => {
    const formData = {
      avatar,
      fullName,
      email,
      phone,
      role,
      emailAlert,
      smsAlert,
      selectedActivityIds,
      mimeType,
      base64,
    };
    onSave(formData);

    setAvatar("");
    setFileAvatar(null);
    setMimeType("");
    setBase64("");
    setFullName("");
    setEmail("");
    setPhone("");
    setRole("");
    setEmailAlert(false);
    setSmsAlert(false);
    setSelectedActivityIds([]);
  };

  return isEdit ? (
    <Card
      sx={{
        width: "30.5%",
        minWidth: "30.5%",
        height: "100%",
        padding: 3,
        borderRadius: 5,
        backgroundColor: "#F9F9F9",
        border: "none",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ justifyContent: "space-between", width: "100%" }}
        >
          <div style={{ width: "43px" }}></div>

          <Stack
            direction="row"
            alignItems="center"
            position={"relative"}
            spacing={1}
            sx={{ cursor: "pointer" }}
          >
            <Avatar
              src={avatar ? avatar : DefaultProfile}
              sx={{ width: 80, height: 80 }}
            />
            <IconButton
              aria-label="edit"
              color="primary"
              sx={{ position: "absolute", right: -10, bottom: -10 }}
              onClick={() => fileInputRef.current?.click()}
            >
              <Edit />
            </IconButton>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  setFileAvatar(file);
                  setAvatar(URL.createObjectURL(file));

                  const mimeType = file.type;
                  setMimeType(mimeType);

                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64 = reader.result as string;
                    setBase64(base64);
                  };
                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
          </Stack>

          <IconButton
            aria-label="close"
            color="primary"
            onClick={() => setIsEdit(false)}
          >
            <Close />
          </IconButton>
        </Stack>

        <Grid container sx={{ gap: 2 }}>
          <Grid item xs={12}>
            <TextField
              type="text"
              placeholder={t("inputCard.fullname")}
              label={t("inputCard.fullname")}
              style={{ width: "95%" }}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text"
              placeholder={t("inputCard.email")}
              label={t("inputCard.email")}
              style={{ width: "95%" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            gap: 2,
          }}
        >
          <MuiTelInput
            fullWidth
            value={phone}
            onChange={setPhone}
            variant="outlined"
            inputProps={{
              pattern:
                "(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})\\s*(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+)\\s*)?$",
            }}
            sx={{
              mt: 0,
              width: "100%",
              "& input": {
                padding: "14px 0",
                border: "none",
              },
              border: "none",
              outline: "none",
            }}
          />
        </Grid>

        <Grid
          container
          sx={{
            gap: 2,
          }}
          style={{ height: "170px" }}
        >
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t("inputCard.role")}</InputLabel>
              <Select
                value={role}
                label={t("inputCard.role")}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="Administrateur">{t("administrator")}</MenuItem>
                <MenuItem value="Membre">{t("member")}</MenuItem>
                <MenuItem value="Invité">{t("invite")}</MenuItem>
              </Select>
            </FormControl>

            {role === "Membre" && (
              <>
                <p
                  style={{
                    marginLeft: "10px",
                    color: "#004643",
                    fontWeight: "700",
                    marginTop: "0",
                    marginBottom: "3px",
                  }}
                >
                  {t("inputCard.editor_access")} :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {t("inputCard.editor_member_value")}
                  </span>
                </p>
                <p
                  style={{
                    marginLeft: "10px",
                    color: "#004643",
                    fontWeight: "700",
                    marginTop: "0",
                  }}
                >
                  {t("inputCard.activities_no_access")} :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {t("inputCard.activities_member_value")}
                  </span>
                </p>
              </>
            )}
            {role === "Invité" && (
              <>
                <p
                  style={{
                    marginLeft: "10px",
                    color: "#004643",
                    fontWeight: "700",
                    marginTop: "0",
                    marginBottom: "3px",
                  }}
                >
                  {t("inputCard.editor_access")} :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {t("inputCard.editor_invite_value")}
                  </span>
                </p>
                <p
                  style={{
                    marginLeft: "10px",
                    color: "#004643",
                    fontWeight: "700",
                    marginTop: "0",
                  }}
                >
                  {t("inputCard.no_access")} :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {t("inputCard.no_access_invite_value")}
                  </span>
                </p>
              </>
            )}
            {role === "Administrateur" && (
              <p
                style={{
                  marginLeft: "10px",
                  color: "#004643",
                  fontWeight: "700",
                  marginTop: "0",
                }}
              >
                {t("inputCard.editor_access")} :{" "}
                <span style={{ fontWeight: "300" }}>
                  {t("inputCard.all_modules")}
                </span>
              </p>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            gap: 2,
          }}
        >
          <Grid item xs={12}>
            <InputMultiSelect
              value={selectedActivityIds.map((id) => id.toString())}
              onChange={handleSelectChange}
              label={t("inputCard.activities")}
              id={id || "activity_id"}
              placeholder={t("inputCard.activities")}
              name={"activity_id"}
              options={dataActivities.map(
                (activity: { id: { toString: () => any }; name: any }) => ({
                  value: activity.id.toString(),
                  label: activity.name,
                })
              )}
              required
              error={null}
              t={t}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            gap: 2,
          }}
          paddingLeft={1.5}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <IOSSwitchLabel
                checked={emailAlert}
                label={t("inputCard.toggle_email")}
                value={emailAlert}
                setValue={(v) => setEmailAlert(v)}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              <IOSSwitchLabel
                checked={smsAlert}
                label={t("inputCard.toogle_sms")}
                value={smsAlert}
                setValue={(v) => setSmsAlert(v)}
              />
            </div>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="info"
          disabled={
            !fullName ||
            !email ||
            !phone ||
            !role ||
            selectedActivityIds.length === 0
          }
          onClick={handleSave}
        >
          {t("inputCard.create_btn")}
        </Button>
      </Stack>
    </Card>
  ) : (
    <Card
      sx={{
        width: "30.5%",
        minWidth: "30.5%",
        padding: 3,
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => setIsEdit(true)}
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          position={"relative"}
          sx={{
            cursor: "pointer",
          }}
        >
          <Avatar src={DefaultProfile} sx={{ width: 80, height: 80 }} />
          <IconButton
            aria-label="edit"
            color="primary"
            sx={{
              position: "absolute",
              right: -10,
              bottom: -10,
            }}
          >
            <AddCircleRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CardNewUser;
