import React from "react";
import { Activity } from "backend/api/activities";
import { Discount } from "backend/api/discounts";

import { DiscountListContainer } from "./style";
import DiscountCard from "./DiscountCard";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ActivityWithDiscountId extends Activity {
  discountId: number;
}

export interface DiscountWithActivities extends Discount {
  activities: ActivityWithDiscountId[];
  associatedDiscountIds: number[];
}

interface DiscountListProps {
  discounts: Discount[];
  activities: Activity[];
}

const DiscountList = ({ discounts, activities }: DiscountListProps) => {
  const { t } = useTranslation("discounts");

  const discountsWithActivities: DiscountWithActivities[] = discounts.reduce(
    (acc: DiscountWithActivities[], discount) => {
      const existingDiscount = acc.find((d) => d.code === discount.code);

      if (existingDiscount) {
        existingDiscount.activities.push(
          ...activities
            .filter((activity) => discount.activityId === activity.id)
            .map((activity) => ({ ...activity, discountId: discount.id })),
        );
        existingDiscount.associatedDiscountIds.push(discount.id);
        existingDiscount.associatedDiscountIds = Array.from(
          new Set(existingDiscount.associatedDiscountIds),
        );
      } else {
        acc.push({
          ...discount,
          activities: activities
            .filter((activity) => discount.activityId === activity.id)
            .map((activity) => ({ ...activity, discountId: discount.id })),
          associatedDiscountIds: [discount.id],
        });
      }

      return acc;
    },
    [],
  );

  return (
    <DiscountListContainer>
      {discountsWithActivities && discountsWithActivities.length > 0 ? (
        discountsWithActivities.map((discount, index) => (
          <React.Fragment key={index}>
            <DiscountCard key={discount.id} discount={discount} />
            {index !== discountsWithActivities.length - 1 && (
              <Divider sx={{ width: "100%", mt: "30px", mb: "30px" }} />
            )}
          </React.Fragment>
        ))
      ) : (
        <p>{t("noDiscounts")}</p>
      )}
    </DiscountListContainer>
  );
};

export default DiscountList;
