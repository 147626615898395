import axios from "axios";
import { WidgetType } from "components/tabs/widget/WidgetTab.types";
import { getAuthorizedConfig } from "helpers/helpers";

export async function upsertWidget(data: WidgetType) {
  let config: any = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/widgets/`;
  config["data"] = data;
  const response = await axios.request({
    ...config,
    maxContentLength: 100000000,
    maxBodyLength: 1000000000,
  });
  return response;
}

export async function getWidgets() {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/widgets`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}
