import Navbar from "components/navigation/Navbar";
import "./WebsitePage.css";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import React, { useMemo, useState } from "react";
import WidgetTab from "components/tabs/widget/WidgetTab";
import ChannelCard from "components/channelCard/ChannelCard";
import { arePropsEqual } from "helpers/helpers";

const WebsitePage = () => {
  const { t } = useTranslation("website");
  const channels = useMemo(
    () => [
      {
        name: "widget",
        label: t("website"),
        icon: LanguageIcon,
        tab: WidgetTab,
      },
    ],
    [t],
  );
  const [selectedTab, setSelectedTab] = useState(channels[0]);

  return (
    <div className="website-page-container">
      <Navbar />
      <div className="website-page-content">
        <div className="section website-page-header">
          <span className="section-title">{t("channels")}</span>
          <div className="website-channels">
            {channels.map((channel, index) => (
              <ChannelCard
                key={index}
                channel={channel}
                tabSelectFct={setSelectedTab}
                isActive={selectedTab.name === channel.name}
              />
            ))}
          </div>
        </div>
        {React.createElement(selectedTab.tab)}
      </div>
    </div>
  );
};

export default React.memo(WebsitePage, arePropsEqual);
