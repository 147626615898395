// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-selector {
  display: grid;
  grid-template-columns: 7rem 1fr;
  gap: 20px;
  align-items: center;
  padding-left: 10px;
}
.color-sample-container {
  display: flex;
  padding: 6px 8px;
  border: 1px solid #878787;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.color-sample {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 0.5px solid #878787;
}
.color-code {
  font-size: 14px;
}

.color-label {
  color: #004643;
  font-size: 18px;
  font-weight: 600;
}

.rcp-root {
  width: 17rem;
  position: absolute;
  z-index: 1000;
}
`, "",{"version":3,"sources":["webpack://./src/components/colorSelector/ColorSelector.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,SAAS;EACT,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;AACA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".color-selector {\n  display: grid;\n  grid-template-columns: 7rem 1fr;\n  gap: 20px;\n  align-items: center;\n  padding-left: 10px;\n}\n.color-sample-container {\n  display: flex;\n  padding: 6px 8px;\n  border: 1px solid #878787;\n  align-items: center;\n  gap: 5px;\n  cursor: pointer;\n}\n.color-sample {\n  display: block;\n  height: 20px;\n  width: 20px;\n  border-radius: 3px;\n  border: 0.5px solid #878787;\n}\n.color-code {\n  font-size: 14px;\n}\n\n.color-label {\n  color: #004643;\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.rcp-root {\n  width: 17rem;\n  position: absolute;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
