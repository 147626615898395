import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import {
  FormBox,
  FourthTitleText,
  GreenBoxWithTitle,
  ThirdTitleText,
} from "../../../helpers/generalStyles";
import { Activity, Availability } from "../../../backend/api/activities";
import { BookingFormBody, BookingFormSection } from "../bookingsStyles";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { BookingFormValidator, PriceForm } from "./bookingFormValidator";
import PriceInput from "../PriceInput";
import { BigInputText, InputText } from "../../formInputs/Inputs";
import { activityCategories } from "helpers/constants";

export default function BookingFormGroup2(props: {
  form: UseFormReturn<BookingFormValidator>;
  formPrices: any;
  activity: Activity;
  availability: Availability;
  numberOfTicketsInTheBooking: number;
  setPage?: any;
  t: any;
}) {
  const {
    form,
    formPrices,
    activity,
    availability,
    numberOfTicketsInTheBooking,
    setPage,
    t,
  } = props;
  const isInCarroussel = setPage ? true : false;
  const {
    control,
    formState: { errors },
    trigger,
  } = form;
  const values = form.getValues();
  const pageBlockingErrors: any = ["prices", "fullName", "email", "phone"];

  const onPreviousPage = () => {
    setPage(1);
  };

  const onClickNextPage = async () => {
    // trigger function allow to launch validation only on fields we pass in parameters of the function
    const isValid = await trigger(pageBlockingErrors);
    if (isValid) setPage(3);
    else console.log({ errors });
  };
  return (
    <BookingFormBody>
      <ThirdTitleText>{t("bookingAndPrices").toUpperCase()}</ThirdTitleText>
      <BookingFormSection>
        <PricesDiv>
          <GreenBoxWithTitle>
            {" "}
            {t(activityCategories[activity.informations.category])}
          </GreenBoxWithTitle>
          {formPrices.fields.map((item: any, index: number) => {
            const otherTicketsInTheUpdate = values["prices"].reduce(
              (acc: number, curr: PriceForm) =>
                (acc +=
                  curr.number && curr.name !== item.name
                    ? Number(curr.number)
                    : 0),
              0
            );
            return (
              // @ts-ignore
              <PriceInput
                id={index}
                name={`${"prices"}.${index}.number`}
                control={control}
                formPrices={formPrices}
                price={values.prices[index]}
                numberOfAvailablePlaces={
                  Number(availability.maxCapacity || 0) -
                  Number(availability.numberOfUnitBooked || 0)
                }
                maxCapacity={Number(availability.maxCapacity || 0)}
                ticketsAlreadyInBooking={numberOfTicketsInTheBooking}
                otherTicketsInTheUpdate={otherTicketsInTheUpdate}
                error={errors.prices ? errors?.prices[index]?.number : null}
                t={t}
              />
            );
          })}
        </PricesDiv>
      </BookingFormSection>
      {isInCarroussel ? (
        <CarrousselVisitorDataInputs control={control} errors={errors} t={t} />
      ) : (
        <OnePageVisitorDataInputs control={control} errors={errors} t={t} />
      )}
      <Button
        style={{
          display: isInCarroussel ? "initial" : "none",
          position: "absolute",
          bottom: 25,
          left: 50,
        }}
        color="info"
        variant="contained"
        onClick={onPreviousPage}
        type="button"
      >
        {t("previous")}
      </Button>
      <Button
        style={{
          display: isInCarroussel ? "initial" : "none",
          position: "absolute",
          bottom: 25,
          right: 50,
        }}
        onClick={onClickNextPage}
        color="info"
        variant="contained"
        type="button"
      >
        {t("next")}
      </Button>
    </BookingFormBody>
  );
}

function CarrousselVisitorDataInputs(props: {
  control: any;
  errors: any;
  t: any;
}) {
  const { control, errors, t } = props;
  return (
    <BookingFormSection>
      <FourthTitleText>{t("fillVisitorData")}</FourthTitleText>
      <VisitorDataContainer style={{ paddingBottom: "75px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
          <BigInputText
            id={"fullName"}
            name={"fullName"}
            placeholder={t("fullName")}
            required
            control={control}
            error={errors?.fullName?.message}
            t={t}
          />
          <BigInputText
            id={"email"}
            name={"email"}
            placeholder={t("email")}
            required
            control={control}
            error={errors?.email?.message}
            t={t}
          />
          <BigInputText
            id={"phone"}
            name={"phone"}
            placeholder={t("phone")}
            required
            control={control}
            error={errors?.phone?.message}
            t={t}
          />
        </div>
      </VisitorDataContainer>
    </BookingFormSection>
  );
}

function OnePageVisitorDataInputs(props: {
  control: any;
  errors: any;
  t: any;
}) {
  const { control, errors, t } = props;

  const value = useWatch({
    control,
    name: "phone",
  });
  return (
    <BookingFormSection>
      <FourthTitleText>{t("fillVisitorData")}</FourthTitleText>
      <VisitorDataContainer>
        <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
          <InputText
            id={"fullName"}
            name={"fullName"}
            label={t("fullName")}
            required
            control={control}
            error={errors?.fullName?.message}
            t={t}
          />
          <InputText
            id={"email"}
            name={"email"}
            label={t("email")}
            required
            control={control}
            error={errors?.email?.message}
            t={t}
          />
          {/* <InputText id={"phone"} name={"phone"} label={t("phone")} required control={control} error={errors?.phone?.message} t={t}/> */}

          <FormBox>
            <Controller
              name={"phone"}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  inputProps={{
                    type: "text",
                    inputMode: "text",
                  }}
                  label={t("phone")}
                  multiline={false}
                  rows={4}
                  variant="outlined"
                  fullWidth={true}
                  type={"text"}
                  error={Boolean(errors?.phone?.message)}
                  helperText={
                    errors?.phone?.message ? t(errors?.phone?.message) : ""
                  }
                  value={value || value === 0 ? value : ""}
                  onChange={(event) => {
                    const newValue = event.target.value;

                    if (/^\d*$/.test(newValue)) {
                      Number(newValue) < 0 || newValue === ""
                        ? field.onChange(null)
                        : field.onChange(newValue);
                    }
                  }}
                />
              )}
            />
          </FormBox>
        </div>
      </VisitorDataContainer>
    </BookingFormSection>
  );
}

const PricesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 450px;
`;

const VisitorDataContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
`;
