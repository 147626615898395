import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CloseIcon from "@mui/icons-material/Close";

import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "@mui/material";

const Assistent = () => {
  const { t } = useTranslation("general");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "50px",
        right: "50px",
        zIndex: 50,
      }}
    >
      {isOpen ? (
        <ContactBox>
          <ContactBoxHeader>
            <ContactSupportIcon style={{ color: "#fff" }} />
            <div
              style={{
                fontSize: "20px",
              }}
            >
              {t("needAssistance")}
            </div>
            <CloseIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => setIsOpen(false)}
            />
          </ContactBoxHeader>

          <ContactBoxText>{t("needAssistanceText")}</ContactBoxText>

          <div
            style={{
              borderRadius: "12px",
              padding: "6px 0px",
              margin: "0px 40px",
              width: "100%",
              textAlign: "center",
              backgroundColor: "white",
              color: "#004643B2",
              fontSize: "14px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            <a
              href="mailto:support.team@hopleisure.com"
              style={{
                color: "#004643B2",
                textDecoration: "none",
              }}
            >
              support.team@hopleisure.com
            </a>
          </div>

          <div
            style={{
              borderRadius: "12px",
              padding: "6px 0px",
              margin: "0px 40px",
              width: "100%",
              textAlign: "center",
              backgroundColor: "white",
              color: "#004643B2",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            +33 6 95 38 21 22
          </div>
        </ContactBox>
      ) : (
        <Box
          onClick={() => setIsOpen(true)}
          sx={{
            cursor: "pointer",
            padding: "5px 5px 1px 5px",
            backgroundColor: "#4fd1c5",
            borderRadius: "100%",
            boxShadow: `
              0px 4px 10px rgba(0, 0, 0, 0.15),
              0px -2px 4px rgba(255, 255, 255, 0.9),
              0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              inset 0px 2px 4px rgba(255, 255, 255, 0.8)
            `,
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: "#38b2ac",
              transform: "scale(1.05)",
              boxShadow: `
                0px 6px 12px rgba(0, 0, 0, 0.2),
                0px -2px 4px rgba(255, 255, 255, 0.9),
                0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                inset 0px 2px 4px rgba(255, 255, 255, 0.8)
              `,
            },
          }}
        >
          <ContactSupportIcon style={{ color: "white" }} />
        </Box>
      )}
    </div>
  );
};

export default Assistent;

const ContactBox = styled.div`
  background-color: #4fd1c5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  border-radius: 15px;
  margin: 5px;
  gap: 5px;
  padding: 20px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2),
    0px -2px 4px rgba(255, 255, 255, 0.9), 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    inset 0px 2px 4px rgba(255, 255, 255, 0.8);
`;
const ContactBoxHeader = styled.div`
  display: flex;
  font-weight: 800;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ContactBoxText = styled.div`
  padding-left: 13px;
  padding-right: 13px;
  font-size: 18px;
  text-align: center;
`;
