import axios from "axios";
import { getAuthorizedConfig } from "../../helpers/helpers";

export async function getClientSetting(): Promise<any> {
  const clientId = localStorage.getItem("clientId");
  let config = getAuthorizedConfig("GET");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/client-settings/${clientId}`;
  const response = await axios.request(config);
  return response.data;
}

export async function updateClientSetting(data: any): Promise<any> {
  let config = getAuthorizedConfig("PUT");
  config["url"] = `${process.env.REACT_APP_API_URL}/activities/emailTemplate`;
  config["data"] = data;
  const response = await axios.request(config);
  return response.data;
}

export async function getEmailTemplates(type?: string): Promise<any> {
  let config = getAuthorizedConfig("GET");
  config[
    "url"
  ] = `${process.env.REACT_APP_API_URL}/email-templates/?type=${type}`;
  const response = await axios.request(config);
  return response.data;
}

export async function getEmailTemplateById(id: string): Promise<any> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/email-templates/${id}`;
  const response = await axios.request(config);
  return response.data;
}

export async function createEmailTemplate(data: any): Promise<any> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/email-templates`;
  config["data"] = data;
  const response = await axios.request(config);
  return response.data;
}

export async function deleteEmailTemplate(id: string): Promise<any> {
  let config = getAuthorizedConfig("DELETE");
  config["url"] = `${process.env.REACT_APP_API_URL}/email-templates/${id}`;
  const response = await axios.request(config);
  return response.data;
}

export async function updateEmailTemplate(data: any): Promise<any> {
  let config = getAuthorizedConfig("PUT");
  config["url"] = `${process.env.REACT_APP_API_URL}/email-templates/${data.id}`;
  config["data"] = data;
  const response = await axios.request(config);
  return response.data;
}