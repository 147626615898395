import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDown } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { GetAllClientActivitiesNameAndIdsOutput } from "backend/api/dashboard";
import { activityCategories } from "helpers/constants";
import useSessionStorage from "hooks/useSessionStorage";

interface SelectedItem {
  id: number;
  checked: boolean;
  name: string;
  disabled: boolean;
  label: string;
}

const DropdownActivities = ({
  activitiesData,
  t,
  selected,
}: {
  activitiesData: GetAllClientActivitiesNameAndIdsOutput;
  t: any;
  selected: (value: { label: string; id: number }[]) => void;
}) => {
  const [activitySelected, setActivitySelected] = useSessionStorage<
    SelectedItem[]
  >("selectedActivity", []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (activitySelected.length > 0) {
      return;
    }

    if (activitiesData.length > 0) {
      const newActivity: SelectedItem[] = activitiesData.map((data, index) => {
        return {
          id: Number(data.id),
          checked: index <= 5 ? true : false,
          name: data.name,
          label: data.name,
          disabled: false,
        };
      });

      setActivitySelected(newActivity);
    }
  }, []);

  const [chekedCount, setChekedCount] = useState<number>(0);

  useEffect(() => {
    if (activitySelected.length > 0) {
      const checkedCount = activitySelected.filter(
        (item: any) => item.checked
      ).length;

      if (checkedCount >= 6) {
        activitySelected.forEach((item: any) => {
          if (!item.checked) item.disabled = true;
        });
      } else {
        activitySelected.forEach((item: any) => {
          item.disabled = false;
        });
      }
      setChekedCount(checkedCount);
    }
  }, [activitySelected]);

  useEffect(() => {
    const data = activitySelected
      .filter((item: any) => item.checked)
      .map((item: any) => {
        return {
          id: item.id,
          label: item.label,
        };
      });

    selected(data);
  }, [activitySelected]);

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          backgroundColor: "#2CA58D",
          borderRadius: 8,
          color: "white",
          height: 30,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{t("titleDropdownDefault").toUpperCase()}</span>
          <ArrowDropDown style={{ color: "white" }} fontSize="small" />
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ height: 350 }}
      >
        {activitySelected.map((data: any, index: any) => (
          <MenuItem
            key={data.id}
            onClick={() => {
              if (!data.disabled) {
                if (chekedCount !== 1) {
                  const updateSelected = activitySelected;
                  updateSelected[index].checked = !data.checked;

                  setActivitySelected([...updateSelected]);
                } else {
                  if (!data.checked) {
                    const updateSelected = activitySelected;
                    updateSelected[index].checked = true;

                    setActivitySelected([...updateSelected]);
                  }
                }
              }
            }}
            style={{
              color: data.checked ? "#2CA58D" : "",
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Checkbox
              style={{ width: 10, height: 0 }}
              size="small"
              checked={data.checked}
            />
            <span style={{ fontSize: 16, marginTop: 2 }}>{data.name}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownActivities;
