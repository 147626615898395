import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import auth from "./auth";
import error from "./error";
import general from "./general";
import activities from "./activities";
import bookings from "./bookings";
import profile from "./profile";
import discounts from "./discounts";
import agenda from "./agenda";
import dashboard from "./dashboard";
import emailing from "./emailing";

const resources = {
  en: {
    general: general.en,
    auth: auth.en,
    activities: activities.en,
    dashboard: dashboard.en,
    bookings: bookings.en,
    profile: profile.en,
    discounts: discounts.en,
    agenda: agenda.en,
    error: error.en,
    emailing: emailing.en,
  },
  fr: {
    general: general.fr,
    auth: auth.fr,
    activities: activities.fr,
    dashboard: dashboard.fr,
    bookings: bookings.fr,
    profile: profile.fr,
    discounts: discounts.fr,
    agenda: agenda.fr,
    error: error.fr,
    emailing: emailing.fr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
    lng: "fr",
  });

export default i18n;
