import { WidgetType } from "components/tabs/widget/WidgetTab.types";
import { arePropsEqual, toastEmitter } from "helpers/helpers";
import "./WidgetCard.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { upsertWidget } from "backend/api/widgets";

const WidgetCard = (props: {
  widget: WidgetType;
  changeWidgetForm: Function;
  isActive: boolean;
  refetchWidgets: Function;
}) => {
  const { widget, changeWidgetForm, isActive, refetchWidgets } = props;
  const { t } = useTranslation("website");
  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] =
    useState(false);

  const closeDeleteWarningModal = () => {
    setIsDeleteWarningModalOpen(false);
  };

  const { mutate } = useMutation({
    mutationFn: (data: WidgetType) => upsertWidget(data),
    onSuccess: (result: any) => {
      setIsDeleteWarningModalOpen(false);

      const toastMsg = `${result.data.name} ${t("deleteWidgetSuccessfully")}`;
      toastEmitter(toastMsg, "success");
      refetchWidgets();
    },
    onError: (error: any) => {
      console.log(error);
      toastEmitter(t("errors.deleteWidget"), "error");
      setIsDeleteWarningModalOpen(false);
    },
  });
  const setWidgetToDeleted = () => {
    if (widget.id) {
      mutate({ ...widget, isDeleted: true });
    }
  };

  return (
    <>
      <div
        key={widget.id}
        className={`widget-card ${isActive && "active"}`}
        onClick={() => changeWidgetForm(widget)}
      >
        <span>{widget.name}</span>
        {isActive && (
          <DeleteIcon
            style={{
              color: "#F95454",
              cursor: "pointer",
              fontSize: "23px",
            }}
            onClick={() => {
              setIsDeleteWarningModalOpen(true);
            }}
          />
        )}
      </div>
      <Dialog open={isDeleteWarningModalOpen} onClose={closeDeleteWarningModal}>
        <DialogTitle
          style={{
            fontWeight: "600",
            letterSpacing: "1px",
            color: "#2CA58D",
          }}
        >
          {t("deleteWidgetWarningTitle")}
        </DialogTitle>
        <DialogContent>{t("deleteWidgetWarningContent")}</DialogContent>
        <DialogActions style={{ padding: "1rem" }}>
          <Button
            onClick={() => setWidgetToDeleted()}
            variant="contained"
            color="error"
          >
            {t("confirm")}
          </Button>
          <Button
            onClick={closeDeleteWarningModal}
            variant="contained"
            color="success"
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(WidgetCard, arePropsEqual);
