import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import useToggleStore from "store/toggle";

export default function LanguageSelector() {
  const language = useToggleStore((state: any) => state.language);
  const setLanguage = useToggleStore((state: any) => state.toggleLanguage);

  const { t, i18n } = useTranslation("auth");

  useEffect(() => {
    i18n.changeLanguage(language);
    moment.locale(language);
  }, [i18n, language]);

  return (
    <Select
      labelId="language-select"
      id="demo-simple-select-standard"
      value={language}
      onChange={(e) => setLanguage(e.target.value as "en" | "fr")}
      label="language"
      style={{ position: "absolute", top: 25, right: 25 }}
    >
      <MenuItem value={"fr"}>fr</MenuItem>
      <MenuItem value={"en"}>en</MenuItem>
    </Select>
  );
}
