import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// Home
import Agenda from "./pages/Agenda";

// Auth
import Login from "./pages/Login";
import Register from "./pages/Register";
import PasswordResetPage from "./pages/PassWordResetPage";
import NewPassword from "./pages/NewPassword";
import Error from "./pages/Error";
import Activities from "./pages/Activities";
import Bookings from "./pages/Bookings";
import Profile from "./pages/Profile";
import Discounts from "pages/Discounts";
import Dashboard from "./pages/Dashboard";
import Report from "pages/report/multiple/Report";
import ReportSingle from "pages/report/single/ReportSingle";
import { RoleAccess } from "../src/helpers/role";
import Emailing from "pages/Emailing";
import WebsitePage from "pages/website/WebsitePage";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={NoConnectedRoute(<Login />)} />
        <Route path="/register" element={NoConnectedRoute(<Register />)} />
        <Route path="/passwordResetPage" element={<PasswordResetPage />} />
        <Route path="/password" element={<NewPassword />} />
        <Route path="/agenda" element={ConnectedRoute(<Agenda />, "agenda")} />
        <Route
          path="/activities"
          element={ConnectedRoute(<Activities />, "activities")}
        />
        <Route
          path="/dashboard"
          element={ConnectedRoute(<Dashboard />, "dashboard")}
        />
        <Route
          path="/discounts"
          element={ConnectedRoute(<Discounts />, "discounts")}
        />
        <Route
          path="/bookings"
          element={ConnectedRoute(<Bookings />, "bookings")}
        />
        <Route
          path="/profile"
          element={ConnectedRoute(<Profile />, "profile")}
        />
        <Route
          path="/facturation"
          element={ConnectedRoute(<Profile />, "profile")}
        />
        <Route
          path="/website"
          element={ConnectedRoute(<WebsitePage />, "website")}
        />
        <Route
          path="/emailing"
          element={ConnectedRoute(<Emailing />, "emailing")}
        />
        <Route path="/generate/report" element={<Report />} />
        <Route path="/generate/report/:id" element={<ReportSingle />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

function NoConnectedRoute(Element: any) {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? <Navigate to="/agenda" /> : Element;
}

function ConnectedRoute(Element: any, page: string) {
  const role = localStorage.getItem("role");
  const accessToken = localStorage.getItem("accessToken");
  const roleAccess = role ? RoleAccess[role as keyof typeof RoleAccess] : null;

  return accessToken && roleAccess?.includes(page) ? (
    Element
  ) : accessToken && !roleAccess ? (
    <Navigate to="/not-found" />
  ) : (
    <Navigate to="/login" />
  );
}
export default Router;
