// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

* { 
  font-family: 'Nunito', sans-serif;
}

.MuiBox-root{
  border-radius: 8px;
  padding: 30px;
  background-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cbc8f0;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d5db5e;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');\n\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 16px;\n}\n\n* { \n  font-family: 'Nunito', sans-serif;\n}\n\n.MuiBox-root{\n  border-radius: 8px;\n  padding: 30px;\n  background-color: white;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #cbc8f0;\n  border-radius: 5px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #d5db5e;\n  border-radius: 5px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
