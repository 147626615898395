const ResponseError = (error: any, push: (v: string) => void) => {
  console.log(error);

  if (error.response.data === "UNAUTHORIZED" || error.response.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("clientId");
    localStorage.removeItem("user");
    window.location.reload();

    push("/login");
  } else {
    console.log(error.response.data);
  }
};

export default ResponseError;
