import styled from "styled-components";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Cancel from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getAllClientActivitiesNameAndIds } from "backend/api/dashboard";
import {
  createEmailTemplate,
  deleteEmailTemplate,
  getClientSetting,
  getEmailTemplateById,
  getEmailTemplates,
  updateClientSetting,
  updateEmailTemplate,
} from "backend/api/emailing";
import ModalPreview from "components/emailing/ModalPreview";
import AlertMessage from "components/general/AlertMessage";
import Navbar from "components/navigation/Navbar";
import { Message } from "helpers/helpers";
import ResponseError from "hooks/ResponseError";
import React, { CSSProperties, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Assistent from "components/navigation/Assistent";
import { useNavigate } from "react-router-dom";

export default function Emailing() {
  const containerStyle: CSSProperties = {
    display: "flex",
    padding: "20px",
    gap: "20px",
    backgroundColor: "#f5f5f5",
    fontFamily: "Arial, sans-serif",
  };

  const cardStyle: CSSProperties = {
    width: "50%",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    position: "relative",
  };

  const titleStyle: CSSProperties = {
    color: "#2CA58D",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  };

  const chipStyle: CSSProperties = {
    borderRadius: "20px",
    border: "1px solid #e0e0e0",
    margin: "5px",
    display: "inline-block",
    fontSize: "14px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(255, 255, 255, 0.3)",
  };

  const activeChipStyle: CSSProperties = {
    ...chipStyle,
    border: "1px solid #e0e0e0",
    boxShadow:
      "0px 6px 12px rgba(0, 0, 0, 0.15), 0px 3px 6px -3px rgba(255, 255, 255, 0.3)",
  };

  const attachmentStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "15px",
    marginBottom: "50px",
  };

  const btnRight: CSSProperties = {
    position: "absolute",
    bottom: "20px",
    padding: "0px 20px",
    right: "0",
    left: "0",
  };

  const icon: CSSProperties = {
    backgroundColor: "#dcf8f1",
    width: "96px",
    height: "46px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
  };

  const inputStyle: CSSProperties = {
    width: "100%",
    padding: "8px",
    marginBottom: "15px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    boxSizing: "border-box",
  };

  const textareaStyle: CSSProperties = {
    width: "100%",
    // height: "150px",
    padding: "8px",
    marginBottom: "10px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    boxSizing: "border-box",
    resize: "none",
  };

  const buttonStyle: CSSProperties = {
    padding: "10px 20px",
    borderRadius: "20px",
    border: "1px solid #009688",
    backgroundColor: "white",
    color: "#009688",
    cursor: "pointer",
    marginRight: "10px",
  };

  const primaryButtonStyle: CSSProperties = {
    ...buttonStyle,
    backgroundColor: "#009688",
    color: "white",
    border: "none",
  };

  const tableStyle: CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    marginBottom: "120px",
  };

  const tableCellStyle: CSSProperties = {
    padding: "12px 8px",
    textAlign: "left",
    color: "#004346",
  };
  const tableCellStyleOne: CSSProperties = {
    ...tableCellStyle,
    // width: '150px'
  };
  const tableCellStyleTwo: CSSProperties = {
    ...tableCellStyle,
    // width: '200px'
  };

  const noteStyle: CSSProperties = {
    fontSize: "14px",
    color: "#666",
    textAlign: "left",
  };

  const saveButtonStyle: CSSProperties = {
    ...buttonStyle,
    width: "100%",
    backgroundColor: "#f5f5f5",
    marginTop: "10px",
  };

  const desc: CSSProperties = {
    fontSize: "12px",
    color: "#009688",
    margin: "8px",
  };

  const { t, i18n } = useTranslation("emailing");
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  interface Activity {
    name: string;
    isOpenInput1: boolean;
    isOpenInput2: boolean;
    valueInput1: string | null;
    valueInput2: string | null;
  }

  const navigate = useNavigate();
  const [isLocked, setIsLocked] = useState({
    cardLeft: false,
    cardRight: false,
  });

  const [activities, setActivities] = useState<Activity[]>([]);
  const [sendByEmail, setSendByEmail] = useState(false);
  const [sendBySms, setSendBySms] = useState(false);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [typeTemplate, setTypeTemplate] = useState("Rappel");

  // first = get (first index)
  // after update = id
  // after delete = get (first index)
  // after post = post (new index)
  const [triggerChipSelected, setTriggerChipSelected] = useState<any>("get");

  const [listChips, setListChips] = useState<any>([]);
  const [chipsSelected, setChipsSelected] = useState("");
  const [chipsDeleteSelected, setChipsDeleteSelected] = useState("");

  const [listTemplatesRappel, setListTemplatesRappel] = useState([]);
  const [listTemplatesDemande, setListTemplatesDemande] = useState([]);

  const [name, setName] = React.useState("");
  const [hours, setHours] = React.useState<string>("");
  const [content, setContent] = React.useState("");
  const [contentEn, setContentEn] = React.useState("");

  const [file, setFile] = useState<any>(null);
  const [base64, setBase64] = useState<string>("");
  const [mimeType, setMimeType] = useState<string>("");

  const { refetch: refetchTemplates } = useQuery(
    ["email-templates"],
    () => getEmailTemplates("all"),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const templatesRappel = data.filter(
          (template: any) => template.type === "Rappel"
        );
        const templatesDemande = data.filter(
          (template: any) => template.type === "Demande d’avis"
        );

        setListTemplatesRappel(templatesRappel);
        setListTemplatesDemande(templatesDemande);

        setListChips(data);

        let newChipSelected;
        if (triggerChipSelected === "get") {
          newChipSelected = data?.[0] || {};
        }
        if (triggerChipSelected === "post") {
          newChipSelected = data?.[data.length - 1] || {};
        }
        if (triggerChipSelected === "id") {
          newChipSelected =
            data.find((template: any) => template.id === chipsSelected) || {};
        }

        refetchEmailById(newChipSelected.id);
        setChipsSelected(newChipSelected.id);

        // setName(newChipSelected.name);
        // setHours(newChipSelected.hours);
        // setContent(newChipSelected.content);
        // setTypeTemplate(newChipSelected.type);
        // setFile({
        //     name: newChipSelected.file,
        // });
      },
      onError: (error) => {
        ResponseError(error, navigate);
      },
    }
  );

  const { refetch: refetchActivities } = useQuery({
    queryKey: ["activities"],
    queryFn: () => getAllClientActivitiesNameAndIds(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const newActivities = data.map((activity: any) => ({
        id: activity?.id,
        name: activity?.name,
        isOpenInput1: activity?.reminderTemplateId ? true : false,
        isOpenInput2: activity?.reviewReqTemplateId ? true : false,
        valueInput1: activity?.reminderTemplateId,
        valueInput2: activity?.reviewReqTemplateId,
      }));

      setActivities(newActivities);
    },
  });

  const { refetch: refecthSetting } = useQuery({
    queryKey: ["client-settings"],
    queryFn: () => getClientSetting(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setSendByEmail(data?.clientSettings?.emailFlag);
      setSendBySms(data?.clientSettings?.smsFlag);
    },
  });

  const { refetch: refetchEmailById } = useQuery(
    ["email-template-by-id", chipsSelected],
    {
      queryFn: () => getEmailTemplateById(chipsSelected),
      refetchOnWindowFocus: false,
      enabled: !!chipsSelected,
      onSuccess(data) {
        setChipsSelected(data?.id);
        setName(data?.name);
        setHours(data?.hours);
        setContent(data?.content);
        setContentEn(data?.contentEn);
        setTypeTemplate(data?.type);
        setFile({
          name: data?.fileName,
        });
      },
    }
  );

  const { mutate: mutateCreateEmail, isLoading: isLoadingMutateEmail } =
    useMutation({
      mutationFn: (data: any) => createEmailTemplate(data),
      onSuccess: (result: any) => {
        setTriggerChipSelected("post");
        setIsPreviewOpen(false);
        setAlertMessage({ type: "success", message: result.message });
        refetchTemplates();
        handleResetState();
      },
      onError: (error: any) => {
        console.log(error);
        setAlertMessage({ type: "error", message: error.message });
      },
    });

  const { mutate: mutateUpdateSetting, isLoading: isLoadingUpdateSetting } =
    useMutation({
      mutationFn: (data: any) => updateClientSetting(data),
      onSuccess: (result: any) => {
        setAlertMessage({ type: "success", message: result.message });
        refecthSetting();
        refetchActivities();
      },
      onError: (error: any) => {
        console.log(error);
        setAlertMessage({ type: "error", message: error.message });
      },
    });

  const { mutate: mutateUpdateEmail, isLoading: isLoadingUpdateEmail } =
    useMutation({
      mutationFn: (data: any) => updateEmailTemplate(data),
      onSuccess: (result: any) => {
        setTriggerChipSelected("id");
        setIsPreviewOpen(false);
        setAlertMessage({ type: "success", message: result.message });
        refetchTemplates();
        handleResetState();
      },
      onError: (error: any) => {
        console.log(error);
        setAlertMessage({ type: "error", message: error.message });
      },
    });

  const { mutate: mutateDeleteEmail, isLoading: isLoadingDeleteEmail } =
    useMutation({
      mutationFn: (id: string) => deleteEmailTemplate(id),
      onSuccess: (result: any) => {
        setTriggerChipSelected("get");
        setIsDeleteOpen(false);
        setAlertMessage({ type: "success", message: result.message });
        refetchTemplates();
        handleResetState();
      },
      onError: (error: any) => {
        console.log(error);
        setAlertMessage({ type: "error", message: error.message });
      },
    });

  const registerTemplate = () => {
    const payload = {
      name,
      type: typeTemplate,
      hours: Number(hours),
      content,
      contentEn: contentEn || "",
      file: {
        name: file?.name || "",
        base64File: base64,
        mimeType,
      },
    };

    mutateCreateEmail(payload);
  };

  const updateTemplate = () => {
    let payload: any = {
      id: chipsSelected,
      name,
      type: typeTemplate,
      hours: Number(hours),
      content,
      contentEn: contentEn || "",
      file: {
        name: file?.name || "",
        base64File: base64,
        mimeType,
      },
    };

    mutateUpdateEmail(payload);
  };

  const handleDeleteEmail = () => {
    if (chipsDeleteSelected === "") {
      const newListChip = listChips.filter((v: any) => v.id !== "");
      setListChips(newListChip);
      setIsDeleteOpen(false);

      const newChipSelected = newListChip?.[0];

      setChipsSelected(newChipSelected.id);

      setName(newChipSelected.name);
      setHours(newChipSelected.hours);
      setContent(newChipSelected.content);
      setContentEn(newChipSelected.contentEn);
      setTypeTemplate(newChipSelected.type);
      setFile({
        name: newChipSelected.file,
      });
    } else mutateDeleteEmail(chipsDeleteSelected);
  };

  const handleResetState = () => {
    setName("");
    setHours("");
    setContent("");
    setContentEn("");
    setTypeTemplate("Rappel");
    setFile(null);
    setBase64("");
    setMimeType("");
  };

  const handleUpdateSetting = () => {
    const newActivities = activities.map((item: any) => ({
      activityId: item.id,
      reminderTemplateId: item.valueInput1 || null,
      reviewReqTemplateId: item.valueInput2 || null,
    }));

    const payload = {
      sendByEmail,
      sendBySms,
      data: newActivities,
    };

    mutateUpdateSetting(payload);
  };

  useEffect(() => {
    setHours("");
  }, []);

  return (
    <Page>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />

      <ModalPreview
        t={t}
        type={typeTemplate}
        content={i18n.language === "fr" ? content : contentEn}
        filename={file?.name}
        open={isPreviewOpen}
        handleClose={() => setIsPreviewOpen(false)}
        isLoading={isLoadingMutateEmail || isLoadingUpdateEmail}
        onSubmit={chipsSelected === "" ? registerTemplate : updateTemplate}
      />

      <Modal
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        style={{
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "26px",
              padding: 0,
              textAlign: "center",
            }}
          >
            {t("modalDelete.title")}
          </Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "20px",
              padding: 0,
              textAlign: "center",
              mt: "15px",
              mb: "25px",
            }}
          >
            {t("modalDelete.desc")}
          </Box>

          <Box
            sx={{
              padding: 0,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              gap: "15px",
              width: "70%",
            }}
          >
            <Button
              sx={{
                bgcolor: "#FFE0764A",
                borderRadius: "30px",
                color: "#7E4501",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#f9ca3048",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              disabled={isLoadingDeleteEmail}
              onClick={handleDeleteEmail}
            >
              {t("modalDelete.buttonSecondary")}
            </Button>

            <Button
              sx={{
                bgcolor: "#2CA58D",
                borderRadius: "30px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#248370",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              onClick={() => setIsDeleteOpen(false)}
            >
              {t("modalDelete.buttonPrimary")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Navbar />
      <Assistent />
      <Container>
        {/* <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <EmailingPage />s
                    </Grid>
                    <Grid item xs={6}>
                        <EmailingActivies />
                    </Grid>
                </Grid> */}
        <div style={containerStyle}>
          {/* Left Card */}
          <div style={cardStyle}>
            <IconButton
              aria-label="close"
              color="primary"
              onClick={() => {
                setIsLocked({ ...isLocked, cardLeft: !isLocked.cardLeft });
              }}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
            >
              {isLocked.cardLeft ? (
                <LockOpenIcon
                  fontSize={"small"}
                  color={"secondary"}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <LockIcon
                  fontSize={"small"}
                  color={"primary"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </IconButton>

            <h2 style={titleStyle}>{t("cardLeft.title")}</h2>
            <div
              style={{
                marginBottom: "40px",
                display: "flex",
                flexFlow: "wrap",
                // maxWidth: "100%",
                // width: '500px'
              }}
            >
              {listChips.map(
                (chip: any, index: React.Key | null | undefined) => (
                  <span
                    key={index}
                    style={{
                      ...(chip.id === chipsSelected
                        ? activeChipStyle
                        : chip.id === ""
                        ? { ...chipStyle, padding: "16px" }
                        : chipStyle),
                      cursor: isLocked.cardLeft ? "pointer" : "default",
                      position: "relative",
                      padding: "8px 30px 8px 22px",
                      backgroundColor:
                        chip.id === chipsSelected && isLocked.cardLeft
                          ? "#2ca58d"
                          : isLocked.cardLeft
                          ? "#f3fcfa"
                          : "#f3fcfa",
                      color:
                        chip.id === chipsSelected && isLocked.cardLeft
                          ? "white"
                          : isLocked.cardLeft
                          ? "#2ca58d"
                          : "#00000042",
                    }}
                    onClick={() => {
                      if (!isLocked.cardLeft) return;

                      setChipsSelected(chip.id);
                      // setName(chip.name);
                      // setHours(chip?.hours);
                      // setContent(chip.content);
                      // setTypeTemplate(chip.type);
                      // setFile({
                      //   name: chip.file,
                      // });
                    }}
                  >
                    {chip.name}

                    {chip.id === chipsSelected && isLocked.cardLeft ? (
                      <IconButton
                        color="error"
                        sx={{
                          p: "0px 0px 0px 5px",
                          cursor: "pointer",
                          position: "absolute",
                          right: 6,
                        }}
                        onClick={() => {
                          if (!isLocked.cardLeft) return;

                          setChipsDeleteSelected(chip.id);
                          setIsDeleteOpen(true);
                        }}
                      >
                        <Cancel
                          sx={{
                            fontSize: 20,
                          }}
                        />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </span>
                )
              )}

              {isLocked.cardLeft && (
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (!isLocked.cardLeft) return;
                    if (
                      listChips.find((chip: { id: string }) => chip.id === "")
                    )
                      return;

                    setListChips([
                      ...listChips,
                      {
                        id: "",
                        name: "",
                        hours: "",
                        content: "",
                        contentEn: "",
                        type: "",
                        file: "",
                      },
                    ]);
                    handleResetState();

                    setChipsSelected("");
                  }}
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              )}
            </div>

            <h3 style={titleStyle}>{t("cardLeft.titleForm")}</h3>
            <TextField
              value={name}
              onChange={(v) => setName(v.target.value)}
              label={t("cardLeft.labelName")}
              variant="outlined"
              disabled={!isLocked.cardLeft}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("cardLeft.labelType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeTemplate}
                label={t("cardLeft.labelType")}
                onChange={(event: SelectChangeEvent<string>) =>
                  setTypeTemplate(event.target.value)
                }
                disabled={!isLocked.cardLeft}
              >
                <MenuItem value="Rappel">
                  {t("cardLeft.templateType1")}
                </MenuItem>
                <MenuItem value="Demande d’avis">
                  {t("cardLeft.templateType2")}
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              type="text"
              label={`${t("cardLeft.labelHours")}*`}
              value={hours}
              inputProps={{
                min: 0,
              }}
              variant="outlined"
              disabled={!isLocked.cardLeft}
              onChange={(e) => {
                const newValue = e.target.value;

                if (/^\d*$/.test(newValue)) {
                  Number(newValue) < 0
                    ? setHours("")
                    : setHours(e.target.value);
                }
              }}
            />

            <p style={desc}>*{t("cardLeft.desc")}</p>

            <h3 style={titleStyle}>{t("cardLeft.titleContent")}</h3>

            <span style={{ color: "#2CA58D" }}>Fr :</span>
            <textarea
              disabled={!isLocked.cardLeft}
              style={textareaStyle}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder={t("cardLeft.placeholderTextarea")}
              rows={2}
            />

            <span style={{ color: "#2CA58D" }}>En :</span>
            <textarea
              disabled={!isLocked.cardLeft}
              style={textareaStyle}
              value={contentEn}
              onChange={(e) => setContentEn(e.target.value)}
              placeholder={t("cardLeft.placeholderTextarea")}
              rows={2}
            />

            <div style={attachmentStyle}>
              <span>{t("cardLeft.inputFile")}</span>
              <div
                style={{
                  ...icon,
                  cursor: !isLocked.cardLeft ? "default" : "pointer",
                }}
                onClick={() =>
                  !isLocked.cardLeft ? null : fileInputRef.current?.click()
                }
              >
                <CloudUploadIcon style={{ color: "#28a088" }} />
              </div>
              <div
                style={{
                  position: "relative",
                }}
              >
                {file?.name && isLocked.cardLeft && (
                  <IconButton
                    color="error"
                    sx={{
                      p: "0px 0px 0px 5px",
                      cursor: "pointer",
                      position: "absolute",
                      left: -10,
                    }}
                    onClick={() => {
                      if (!isLocked.cardLeft) return;

                      setFile(null);
                      fileInputRef.current?.value &&
                        (fileInputRef.current.value = "");
                      setBase64("");
                      setMimeType("");
                    }}
                  >
                    <Cancel
                      sx={{
                        fontSize: 20,
                      }}
                    />
                  </IconButton>
                )}

                <p
                  style={{
                    width: "320px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {file?.name}
                </p>
              </div>

              <input
                type="file"
                accept="image/jpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref={fileInputRef}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    setFile(file);

                    const mimeType = file.type;
                    setMimeType(mimeType);

                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64 = reader.result as string;
                      setBase64(base64);
                    };

                    reader.readAsDataURL(file);
                  }
                }}
                style={{ display: "none" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                bottom: "20px",
                padding: "0px 20px",
                right: "0px",
                left: "0px",
              }}
            >
              {/* <Button
                variant="outlined"
                color="info"
                disabled={!isLocked.cardLeft}
                sx={{
                  borderRadius: "20px",
                  padding: "5px 25px",
                  backgroundColor: "#f3fcfa",
                  color: "#2ca58d",
                  boxShadow: `
                    0px 2px 4px rgba(0, 0, 0, 0.1),
                    0px -2px 3px rgba(255, 255, 255, 0.7),
                    0px 4px 6px rgba(0, 0, 0, 0.1),
                    inset 0px 2px 4px rgba(255, 255, 255, 0.6)
                  `,
                  "&:hover": {
                    backgroundColor: "#f3fcfa",
                    boxShadow: `
                      0px 2px 4px rgba(0, 0, 0, 0.15),
                      0px -2px 3px rgba(255, 255, 255, 0.8),
                      0px 4px 6px rgba(0, 0, 0, 0.15),
                      inset 0px 2px 4px rgba(255, 255, 255, 0.7)
                    `,
                  },
                }}
                onClick={() => {
                  handleResetState();
                }}
              >
                {t("cardLeft.buttonSecondary")}
              </Button> */}

              <div></div>
              <Button
                variant="contained"
                color="info"
                disabled={!isLocked.cardLeft || !name || !hours || !content}
                onClick={() =>
                  !isLocked.cardLeft ? null : setIsPreviewOpen(true)
                }
                sx={{
                  borderRadius: "20px",
                  padding: "5px 25px",
                }}
              >
                {t("cardLeft.buttonPrimary")}
              </Button>
            </div>
          </div>

          {/* Right Card */}
          <div style={cardStyle}>
            <IconButton
              aria-label="close"
              color="primary"
              onClick={() => {
                setIsLocked({ ...isLocked, cardRight: !isLocked.cardRight });
                setActivities(
                  activities.map((activity: any) => ({
                    ...activity,
                    isOpenInput1: activity?.valueInput1 ? true : false,
                    isOpenInput2: activity?.valueInput2 ? true : false,
                    valueInput1: activity?.valueInput1,
                    valueInput2: activity?.valueInput2,
                  }))
                );
              }}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
            >
              {isLocked.cardRight ? (
                <LockOpenIcon
                  fontSize={"small"}
                  color={"secondary"}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <LockIcon
                  fontSize={"small"}
                  color={"primary"}
                  style={{ cursor: "pointer" }}
                />
              )}
            </IconButton>

            <h2 style={titleStyle}>{t("cardRight.title")}</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Switch
                  disabled={!isLocked.cardRight}
                  value={sendByEmail}
                  checked={sendByEmail}
                  onChange={(e) => setSendByEmail(e.target.checked)}
                />
                <FormLabel
                  sx={{
                    color: "#004346",
                  }}
                >
                  {t("cardRight.checkboxEmail")}
                </FormLabel>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Switch
                  disabled={!isLocked.cardRight}
                  value={sendBySms}
                  checked={sendBySms}
                  onChange={(e) => setSendBySms(e.target.checked)}
                />
                <FormLabel
                  sx={{
                    color: "#004346",
                  }}
                >
                  {t("cardRight.checkboxSMS")}
                </FormLabel>
              </div>
            </div>

            <div style={{ height: "73%", overflow: "auto" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  textAlign: "left",
                  marginTop: "20px",
                  color: "#004346",
                }}
              >
                <div
                  style={{
                    padding: "12px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {t("cardRight.titleHead1")}
                </div>

                <div
                  style={{
                    padding: "12px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {t("cardRight.titleHead2")}
                </div>
                <div
                  style={{
                    padding: "12px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {t("cardRight.titleHead3")}
                </div>
              </div>
              {activities.map((activity, index) => (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    textAlign: "left",
                    color: "#004346",
                    padding: "12px 8px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "150px" }}>{activity.name}</div>
                  <div>
                    {activity.isOpenInput1 ? (
                      <FormControl
                        sx={{
                          width: "150px",
                          position: "relative",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Évènement déclencheur de l’envoi
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={activity.valueInput1}
                          label="Évènement déclencheur de l’envoi"
                          onChange={(e) => {
                            const newActivities = [...activities];
                            newActivities[index].valueInput1 = e.target.value;
                            setActivities(newActivities);
                          }}
                          disabled={!isLocked.cardRight}
                        >
                          {listTemplatesRappel.map((template: any, index) => (
                            <MenuItem key={index} value={template.id}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </Select>

                        <IconButton
                          aria-label="close"
                          color="error"
                          sx={{
                            position: "absolute",
                            top: -40,
                            right: -10,
                          }}
                          disabled={!isLocked.cardRight}
                          onClick={() => {
                            const newActivities = [...activities];
                            newActivities[index].isOpenInput1 = false;
                            newActivities[index].valueInput1 = null;
                            setActivities(newActivities);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </FormControl>
                    ) : (
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        disabled={!isLocked.cardRight}
                        sx={{
                          width: "150px",
                          justifyContent: "start",
                          ":hover": {
                            backgroundColor: "white",
                          },
                        }}
                        onClick={() => {
                          const newActivities = [...activities];
                          newActivities[index].isOpenInput1 = true;
                          setActivities(newActivities);
                        }}
                      >
                        <AddCircleRoundedIcon />
                      </IconButton>
                    )}
                  </div>
                  <div>
                    {activity.isOpenInput2 ? (
                      <FormControl
                        sx={{
                          width: "150px",
                          position: "relative",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Évènement déclencheur de l’envoi
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={activity.valueInput2}
                          label="Évènement déclencheur de l’envoi"
                          onChange={(e) => {
                            const newActivities = [...activities];
                            newActivities[index].valueInput2 = e.target.value;
                            setActivities(newActivities);
                          }}
                          disabled={!isLocked.cardRight}
                        >
                          {listTemplatesDemande.map((template: any, index) => (
                            <MenuItem key={index} value={template.id}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <IconButton
                          aria-label="close"
                          color="error"
                          sx={{
                            position: "absolute",
                            top: -40,
                            right: -10,
                          }}
                          disabled={!isLocked.cardRight}
                          onClick={() => {
                            const newActivities = [...activities];
                            newActivities[index].isOpenInput2 = false;
                            newActivities[index].valueInput2 = null;
                            setActivities(newActivities);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </FormControl>
                    ) : (
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        disabled={!isLocked.cardRight}
                        sx={{
                          width: "150px",
                          justifyContent: "start",
                          ":hover": {
                            backgroundColor: "white",
                          },
                        }}
                        onClick={() => {
                          const newActivities = [...activities];
                          newActivities[index].isOpenInput2 = true;
                          setActivities(newActivities);
                        }}
                      >
                        <AddCircleRoundedIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div style={btnRight}>
              <p style={noteStyle}>{t("cardRight.desc")}</p>

              {/* <button style={saveButtonStyle}>
                SAUVEGARDER LES TEMPLATES POUR CHAQUE ACTIVITÉ
              </button> */}
              <Button
                variant="contained"
                color="info"
                disabled={!isLocked.cardRight || isLoadingUpdateSetting}
                onClick={handleUpdateSetting}
                sx={{
                  borderRadius: "20px",
                  padding: "5px 25px",
                  width: "100%",
                }}
              >
                {t("cardRight.buttonPrimary")}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  background-color: #e0e1e2;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
`;
