import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AppState {
  user: any;
  updateUser: (payload: any) => void;
  removeUser: () => void;
  updateUserValue: (payload: any) => void;
}

const useStoreUser = create(
  persist<AppState>(
    (set) => ({
      user: {},
      updateUser: (payload: any) => set((state) => ({ user: payload })),
      removeUser: () => set((state) => ({ user: {} })),
      updateUserValue: (payload: any) =>
        set((state) => ({
          user: {
            ...state.user,
            user: payload,
          },
        })),
    }),
    {
      name: "user",
    }
  )
);

export default useStoreUser;
