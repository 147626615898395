import { widgetsReducer } from "reducers/widgetsReducer";
import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

const reducer = combineReducers({
  widgets: widgetsReducer,
});
const initialState = {};
const store = createStore(reducer, initialState, composeWithDevTools());

export default store;

export type RootState = ReturnType<typeof reducer>;
