// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-page-container {
  display: flex;
  background-color: #e0e1e2;
}
.website-page-content {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: min-content 1fr;
  min-height: 100%;
}

.website-channels {
  display: flex;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/website/WebsitePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,mCAAmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".website-page-container {\n  display: flex;\n  background-color: #e0e1e2;\n}\n.website-page-content {\n  width: 100%;\n  padding: 20px;\n  display: grid;\n  grid-row-gap: 20px;\n  grid-template-rows: min-content 1fr;\n  min-height: 100%;\n}\n\n.website-channels {\n  display: flex;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
