import { WidgetType } from "./WidgetTab.types";

export const defaultWidgetColors = {
  widgetBackgroundColor: "#FFF",
  textColorOnBackground: "#000",
  activityCardBackgroundColor: "#FFF",
  activityCardTextColor: "#000",
  actionBtnBackgroundColor: "#FFF",
  actionBtnTextColor: "#064643",
};

export const maxExtraInfos = 3;

export const initialWidget: WidgetType = {
  name: "",
  activitiesIds: [],
  style: { fontFamily: "Nunito", colors: defaultWidgetColors },
  extraInfos: [],
  isDeleted: false,
};
